import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectBuyerPermissions } from 'src/app/features/auth/auth.selectors';

@Directive({
  selector: '[canOffer]'
})
export class CanOfferDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<{}>
  ) {
    this.store.select(selectBuyerPermissions).subscribe(res => {
      if (res.rmk_makeoffer) {
        // If condition is true add template to DOM
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
       // Else remove template from DOM
        this.viewContainer.clear();
      }
    });
  }

}

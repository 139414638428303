import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap, catchError } from 'rxjs/operators';

import * as AuthActions from './auth.actions';
import * as AuctionSelectedActions from './../auctionSelected/auction-selected.actions';
import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/api-wrapped/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { ShowNotifyMessage } from '../layout/layout.actions';



@Injectable()
export class AuthEffects {

    privacyUpdate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.PrivacyUpdate),
            mergeMap((action) => this.userService.privacySettings(action.data)
                .pipe(
                    map(result => {
                        return AuthActions.PrivacyUpdateTerminated({success: true});
                    }),
                    catchError(() => of(AuthActions.PrivacyUpdateTerminated({success: false})))
                )
            )
        );
    });

  loginRequest$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(AuthActions.loginRequest),
      map(action => {
        localStorage.clear();
        return action;
      }), 
      switchMap(({body}) => this.userService.login(body).pipe(
        switchMap(auth => {
          this.authBaseService.setToken(auth.access_token);
          return this.authBaseService.me().pipe(
            mergeMap(userData => {
              const returnUrl = this.activatedRoute.snapshot.queryParams?.returnUrl;
              if (returnUrl) {
                this.router.navigate([returnUrl], {replaceUrl: true});
              } else {
                this.router.navigate(['/'], {replaceUrl: true});
              }
  
              this.authBaseService.setUserData(userData);
              this.translateService.setDefaultLang(userData.locale?.substr(0, 2)); 
  
              return [
                AuthActions.loginSuccess({data: {
                  ...userData,
                  ...auth
                }})
              ];
              
            }),
            catchError(res => of(AuthActions.loginFailure({ error: res.error })))
          )
        }),
        catchError(res => of(AuthActions.loginFailure({ error: res.error })))
      ))
    );
  });

  signupRequest$ = createEffect(() => {
    return this.actions$.pipe(

      ofType(AuthActions.signupRequest),
      switchMap(action => 
        this.userService.signup(action.body).pipe(
          map(res => AuthActions.signupSuccess({data: res})),
          catchError(err => of(AuthActions.signupFailure({ error: err.error })))
        )
      )
    );
  });

  forgotPasswordRequest$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(AuthActions.forgotPasswordRequest),
      switchMap(action => 
        this.userService.forgotPassword(action.body).pipe(
          map(res => AuthActions.forgotPasswordSuccess({data: res})),
          catchError(err => of(AuthActions.forgotPasswordFailure({ error: err.error })))
        )
      )
    );
  });

  forgotPasswordVerifyPin$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(AuthActions.forgotPasswordVerifyPinRequest),
      switchMap(action => 
        this.userService.forgotPasswordVerifyPin(action.body).pipe(
          map(res => AuthActions.forgotPasswordVerifyPinSuccess({data: res})),
          catchError(err => of(AuthActions.forgotPasswordVerifyPinFailure({ error: err.error })))
        )
      )
    );
  });

  acceptTerms$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(AuthActions.AcceptTerms),
      switchMap(action => 
        this.userService.acceptTerms(action.hideToc).pipe(
          map(res => ({dispatchModalStep: action.dispatchModalStep, res}))
        )
      ),
      mergeMap(result => {
        return result.res.level === 'error' ?
          of(AuthActions.AcceptTermsFailure({ 
            error: {
              name: "Error",
              message: result.res.message 
            }
          })): 
          [
            AuthActions.AcceptTermsSuccess(),
            ShowNotifyMessage({message: result.res.message}),
            ...[ result.dispatchModalStep ? AuctionSelectedActions.AcceptTerms(): null],
          ]
        }
      )
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(AuthActions.logout),
      map(_ => {
        this.authBaseService.logout();
        this.router.navigate(['login']);  
      })
    );
  },
  { dispatch: false }
  );

  markNewsAsRead$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(AuthActions.markNewsAsRead),
      map(_ => {
        localStorage.setItem('newsInfo[unread]', '0');
      })
    );
  },
  { dispatch: false }
  );

  setLanguage$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(AuthActions.setLanguage),
      map(action => {
        localStorage.setItem('languageCode', action.languageCode);
        this.translateService.setDefaultLang(action.languageCode); 
      })
    );
  },
  { dispatch: false }
  );
  
  initializeUserForm$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(AuthActions.initializeUserForm),
      switchMap(action => forkJoin([
        action.withProfileData ? this.authBaseService.me(): of({buyer: null, register: null}),
        this.authBaseService.userFormsData()
      ]).pipe(
        mergeMap(res => ([
            AuthActions.initializeUserFormSuccess({data: {
              buyerData: action.withProfileData ? res[0]?.buyer : null,
              register: action.withProfileData ? res[0]?.register : null,
              profileForm: res[1]?.profileForm,
              preferences: res[1]?.preferences
            }})
          ])
        ),
        catchError(res => {
          return of(AuthActions.initializeUserFormFailure({ error: res.error }));
        })
      ))
    );
  });



  constructor(
    private actions$: Actions,
    private authBaseService: AuthService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService
  ) {}

}

import { createAction, props } from '@ngrx/store';
import { News } from 'src/app/core/models/news-dto';

export const LoadNews = createAction(
  '[News] Load News'
);
export const LoadNewsSuccess = createAction(
  '[News] Load News Success',
  props<{records: News[]}>()
);
export const LoadNewsFailure = createAction(
  '[News] Load News Failure'
);

export const DownloadNewsAttachment = createAction(
  '[News] Download News Attachment',
  props<{newsId: number, fileId: number}>()
);

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { StaticValues } from "../../models/static-values-dto";

@Injectable({
    providedIn: 'root',
  })
  export class StaticValuesService {
    constructor(
      private http: HttpClient
    ) {
    }

    Get(): Observable<StaticValues> {
      return this.http.get<StaticValues>(environment.apiUrl + 'buyer/static_values');
    }
}

import { createReducer, on } from "@ngrx/store";
import { GroundingDto } from "src/app/core/models/grounding-dto";
import { GroundingFilterDto } from "src/app/core/models/grounding-filter-dto";
import * as GroundingActions from "./grounding.actions";

export interface State {
  filters: GroundingFilterDto;
  list: GroundingDto[];
  isFetching: boolean;
  fetched: boolean;
  page: number;
  canLoadMoreRecords: boolean;
}

export const initialState: State = {
  filters: {
    quotationWaiting: true,
    quotationSent: true,
    preemptionConfirmed: true,
    preemptionAchievable: true,
    quotationRejected: false,
    quotationNotAchievable: false
  },
  list: [],
  fetched: false,
  isFetching: false,
  page: 1,
  canLoadMoreRecords: true,
};

export const groundingReducer = createReducer(
  initialState,

  on(GroundingActions.LoadGrounding, (state, action) => ({
    ...state,
    isFetching: true,
  })),
  on(GroundingActions.ApplyGroundingFilters, (state) => ({
    ...state,
    list: [],
    fetched: false,
    isFetching: true,
    page: 1,
  })),
  on(GroundingActions.LoadGroundingSuccess, (state, action) => ({
    ...state,
    canLoadMoreRecords: action.canLoadMoreRecords,
    list: action.records,
    isFetching: false,
    fetched: true,
  })),
  on(GroundingActions.LoadGroundingFailure, (state) => ({
    ...state,
    isFetching: false,
    fetched: true,
  })),
  on(GroundingActions.SetGroundingFilter, (state, action) => ({
    ...state,
    filters: {
      ...state.filters,
      ...action.filter,
    },
  })),
  on(GroundingActions.ResetGroundingFilters, (state, action) => ({
    ...state,
    list: [],
    fetched: false,
    page: 1,
    filters: initialState.filters,
  })),
  on(GroundingActions.SetGroundingNextPage, (state, action) => ({
    ...state,
    page: state.page + 1,
  })),
  on(
    GroundingActions.ToggleGroundingPreferenceListSuccess,
    (state, action) => ({
      ...state,
      list: state.list.map((item) => {
        if (item.id === action.id) {
          const newGrounding = new GroundingDto(item);
          let option =
            newGrounding.firstOptions[newGrounding.firstOptions.length - 1];
          if (option) {
            option = { ...option, status: option.status === 1 ? 8 : 1 };
          } else {
            option = { status: 1 };
          }

          newGrounding.firstOptions = [option];

          return newGrounding;
        } else {
          return item;
        }
      }),
    })
  )
);

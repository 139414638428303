import { createAction, props } from '@ngrx/store';
import { AuctionDto, AuctionPricePolling, DistributionVehiclesDto, Doc } from 'src/app/core/models/auction-dto';
import { VehicleDocType, VehicleDto } from 'src/app/core/models/vehicle-dto';

export const LoadAuctionById = createAction(
  '[Auction Detail] Load Auction By Id',
  props<{id: number}>()
);
export const LoadAuctionByIdSuccess = createAction(
  '[Auction Detail] Load Auction By Id Success',
  props<{record: AuctionDto}>()
);
export const LoadAuctionByIdFailure = createAction(
  '[Auction Detail] Load Auction By Id Failure'
);

export const TogglePreference = createAction(
  '[Auction Detail] Toggle Preferences',
  props<{auction: AuctionDto}>()
);
export const TogglePreferenceSuccess = createAction(
  '[Auction Detail] Toggle Preferences Success'
);
export const TogglePreferenceFailure = createAction(
  '[Auction Detail] Toggle Preferences Failure'
);

export const OpenModalAction = createAction(
  '[Auction Detail] Open Action Modal',
  props<{auction: AuctionDto, amount?: number, modalAction: 'deleteOffer' | 'buyNow' | 'makeOffer' | 'distributeValues' | null}>()
);
export const OpenModalActionSuccess = createAction(
  '[Auction Detail] Open Action Modal Success',
  props<{step: number}>()
);
export const OpenModalActionFailure = createAction(
  '[Auction Detail] Open Action Modal Failure',
  props<{ error: any }>()
);
export const MakeOffer = createAction(
  '[Auction Detail] Make Offer',
  props<{fromList: boolean}>()
);
export const MakeOfferSuccess = createAction(
  '[Auction Detail] Make Offer Success',
  props<{auction: AuctionDto}>()
);
export const MakeOfferFailure = createAction(
  '[Auction Detail] Make Offer Failure',
  props<{error: string}>()
);
export const DeleteOffer = createAction(
  '[Auction Detail] Delete Offer',
  props<{fromList: boolean}>()
);
export const DeleteOfferSuccess = createAction(
  '[Auction Detail] Delete Offer Success',
  props<{auction: AuctionDto}>()
);
export const DeleteOfferFailure = createAction(
  '[Auction Detail] Delete Offer Failure',
  props<{error: string}>()
);
export const AcceptTerms = createAction(
  '[Auction Detail] Accept Terms'
);
export const CloseModalAction = createAction(
  '[Auction Detail] Close Action Modal'
);

export const SetDirectOffer = createAction(
  '[Auction Detail] Toggle Offer Once',
  props<{auction: AuctionDto, directOffer: boolean}>()
)

export const BuyNow = createAction(
  '[Auction Detail] Buy Now',
  props<{fromList: boolean}>()
);
export const BuyNowSuccess = createAction(
  '[Auction Detail] Buy Now Success',
  props<{auction: AuctionDto}>()
);
export const BuyNowFailure = createAction(
  '[Auction Detail] Buy Now Failure',
  props<{error: string}>()
);

export const DistributeValues = createAction(
  '[Auction Detail] Distribute Values',
  props<{distribution: DistributionVehiclesDto[]}>()
);
export const DistributeValuesSuccess = createAction(
  '[Auction Detail] Distribute Values Success',
  props<{distribution: DistributionVehiclesDto[]}>()
);
export const DistributeValuesFailure = createAction(
  '[Auction Detail] Distribute Values Failure',
  props<{error: string}>()
);

export const DownloadVehicleDoc = createAction(
  '[Auction Detail] Download Vehicle Doc',
  props<{vehicle: VehicleDto, docType: VehicleDocType}>()
);

export const DownloadAuctionDoc = createAction(
  '[Auction Detail] Download Auction Doc',
  props<{doc: Doc}>()
);

export const GetAuctionPriceDetail = createAction(
  '[Auction] Get Auction Price Detail'
);
export const GetAuctionPriceDetailSuccess = createAction(
  '[Auction] Get Auction Price Detail Success',
  props<{priceList: AuctionPricePolling[]}>()
);
export const GetAuctionPriceDetailFailure = createAction(
  '[Auction] Get Auction Price Detail Failure'
);

export const GenerateVehicleZip = createAction(
  '[Auction] Generate Vehicle Zip',
  props<{vehicle: VehicleDto}>()
);
export const GenerateVehicleZipSuccess = createAction(
  '[Auction] Generate Vehicle Zip Success',
  props<{vehicleId: number}>()
);
export const GenerateVehicleZipFailure = createAction(
  '[Auction] Generate Vehicle Zip Failure'
);
import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromGrounding from "./grounding-returned.reducer";

export const selectGroundingReturnedState =
  createFeatureSelector<fromGrounding.State>("groundingReturned");

export const selectGroundingReturnedPaginated = createSelector(
  selectGroundingReturnedState,
  (state) => {
    return {
      ...state,
      list: state.list,
    };
  }
);

export const selectGroundingReturnedSearchFilter = createSelector(
  selectGroundingReturnedState,
  (state) => state.filters?.plate
);

export const selectGroundingReturnedFilters = createSelector(
  selectGroundingReturnedState,
  (state) => state.filters
);

export const selectGroundingReturnedActiveFiltersCount = createSelector(
  selectGroundingReturnedState,
  (state) =>
    Object.values(state.filters).filter(Boolean).length
);

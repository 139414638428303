import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {setLanguage} from "../../../features/auth/auth.actions";
import {selectAuthState} from "../../../features/auth/auth.selectors";

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangeLanguageComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public authState: any;

  constructor(
    private store: Store<{}>,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.select(selectAuthState).subscribe((state: any) => {
        this.authState = state;
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public setLanguage(languageCode: string): void {
    this.store.dispatch(setLanguage({ languageCode }));
  }
}

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Notification } from 'src/app/core/models/notification-dto';
import { ToggleNotificationsMenu } from 'src/app/features/notification/notification.actions';
import { selectNotificationState } from 'src/app/features/notification/notification.selectors';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationMenuComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public notifications: Notification[] = [];
  public opened = false;
    
  constructor(
    private store: Store<{}>
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.select(selectNotificationState).subscribe(state => {
        this.notifications = state.notifications;
        this.opened = state.menuOpened;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  closeMenu(): void {
    this.store.dispatch(ToggleNotificationsMenu());
  }

}

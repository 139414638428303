import {DOCUMENT} from "@angular/common";
import { Component, HostListener, Inject, Renderer2 } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { WINDOW } from "src/app/shared/services/window.service";
import {NavigationEnd, Router, RouterEvent} from "@angular/router";
import {filter} from "rxjs/operators";

@Component({
    selector: "app-full-layout",
    templateUrl: "./full-layout.component.html",
    styleUrls: ["./full-layout.component.scss"]
  })
  export class FullLayoutComponent {
    isScrollTopVisible = false;
    private currentPath = "";
    private currentWidth = 0;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
        private renderer: Renderer2,
        private deviceService: DeviceDetectorService,
        private router: Router
    ){
      this.currentWidth = window.innerWidth;

      router.events
        .pipe(
          filter(event => event instanceof NavigationEnd)
        )
        .subscribe(val => {
          if (val instanceof RouterEvent) {
            this.currentPath = (val as NavigationEnd).urlAfterRedirects;
          }
      })
    }

    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }

  // Show/hide footer on mobile
  @HostListener("window:resize", [])
  onWindowResize() {
    this.currentWidth = this.window.innerWidth;
  }

  //Add/remove classes on page scroll
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (number > 198) {
      this.renderer.addClass(this.document.body, "navbar-scrolled");
    } else {
      this.renderer.removeClass(this.document.body, "navbar-scrolled");
    }

    if (number > 400) {
      this.isScrollTopVisible = true;
    } else {
      this.isScrollTopVisible = false;
    }
  }

  get isFooterVisible() {
    if (this.currentWidth < 720) {
      // on mobile, is visible only in the "list" pages
      for (let route of ['/auction', '/auction/finished', '/auction/favourite', '/grounding/re-enter', '/news/last']) {
        if (this.currentPath === route) {
          return true;
        }
      }

      return false;
    }

    // on desktop, footer is always visible
    return true;
  }
}

import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromGrounding from "./grounding.reducer";
import {PAGE_SIZE} from "../../shared/constants";

export const selectGroundingState =
  createFeatureSelector<fromGrounding.State>("grounding");

export const selectGroundingPaginated = createSelector(
  selectGroundingState,
  (state) => ({
    ...state,
    totalItems: state.list.length + (state.canLoadMoreRecords ? 1 : 0), // Se l api non da piu records togliamo il pulsante carica più aste,
    list: state.list.slice(0, state.page ? state.page * PAGE_SIZE : PAGE_SIZE),
  })
);

export const selectGroundingSearchFilter = createSelector(
  selectGroundingState,
  (state) => state.filters?.plate
);

export const selectGroundingFilters = createSelector(
  selectGroundingState,
  (state) => state.filters
);

export const selectGroundingActiveFiltersCount = createSelector(
  selectGroundingState,
  (state) =>
    Object.values(state.filters).filter(Boolean).length
);

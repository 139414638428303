import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, concatMap, catchError, switchMap } from 'rxjs/operators';
import { CloseBanner, GetServerTimestamp, GetServerTimestampFailure, GetServerTimestampSuccess, GetStaticValues, GetStaticValuesFailure, GetStaticValuesSuccess } from './layout.actions';
import { TimerService } from 'src/app/core/services/api-wrapped/timer.service';
import { of } from 'rxjs';
import { StaticValuesService } from 'src/app/core/services/api-wrapped/static-values.service';
import { setCookie } from 'src/app/core/functions/cookies.functions';

@Injectable()
export class LayoutEffects {
  constructor (
    private actions$: Actions,
    private staticValuesService: StaticValuesService,
    private timerService: TimerService
  ) {}

  getServerTimestamp$ = createEffect(() => {

      return this.actions$.pipe(
        ofType(GetServerTimestamp),
        concatMap(action => this.timerService.GetTimestamp()),
        map(timestamp => {
          return timestamp ? GetServerTimestampSuccess({
            timestamp
          }) : GetServerTimestampFailure();
        })
      )
    }
  );

  getStaticValues$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(GetStaticValues),
      switchMap(_ => this.staticValuesService.Get().pipe(
        map(res => GetStaticValuesSuccess({data: res})),
        catchError(res => {
          return of(GetStaticValuesFailure({ error: res.error }));
        })
      ))
    );
  });

  closeBanner$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(CloseBanner),
      map(action => setCookie(action.bannerType, 'true', 100))
    )
  },
  { dispatch: false }
  );
}

import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as GalleryActions from './vehicle-gallery.actions';
import { ShowNotifyMessage } from '../layout/layout.actions';
import { AuctionService } from 'src/app/core/services/api-wrapped/auction.service';
import { selectGalleryState } from './vehicle-gallery.selectors';
import { State } from './vehicle-gallery.reducer';
import { Store } from '@ngrx/store';



@Injectable()
export class GalleryEffects {
  
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private auctionService: AuctionService
  ) {}

  openVehicleGallery$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(GalleryActions.OpenVehicleGallery),
      concatLatestFrom(action => this.store.select(selectGalleryState)),
      switchMap(([action, store]) => this.auctionService.GetVehicleGallery(action.vehicleId, action.auctionId).pipe(
        map(res => {
          const existingGallery = store.galleries.find(item => item.vehicleId === action.vehicleId);
          const gallery = existingGallery ? existingGallery : {
            vehicleId: action.vehicleId,
            images: res
          };
          return GalleryActions.OpenVehicleGallerySuccess({
              title: action.title, 
              gallery: {
                ...gallery,
                ...{
                  vehicleId: action.vehicleId
                }
              }
            })
        }),
        catchError(err => [
          ShowNotifyMessage({message: err.error, level: 'error'}),
          GalleryActions.OpenVehicleGalleryFailure({error: err.error})
        ])
      ))
    )
  });

}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from './vehicle-models.reducer';
import * as Models from './vehicle-models.actions';
import { VehicleModelsService } from 'src/app/core/services/api-wrapped/vehicle-model.service';
@Injectable()
export class VehicleModelsEffects {
  constructor (
    private actions$: Actions,
    private store: Store<State>,
    private modelsService: VehicleModelsService
  ) {}

  loadVehicleModels$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(Models.LoadVehicleModels),
      switchMap(action => this.modelsService.Gets({text:action.filter})),
      map(records => {
        return records.length > 0 ? Models.LoadVehicleModelsSuccess({records}) : Models.LoadVehicleModelsFailure();
      })
    )
  });

}

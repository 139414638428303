<section *ngIf="opened" class="c-notification-center__panel" aria-hidden="false">
    <header class="c-notification-center__panel__header">
        <h2 class="c-notification-center__panel__heading u-text-brand">{{ "notification.title" | translate }}</h2>
        <button (click)="closeMenu()" class="c-btn c-notification-center__panel__close c-btn--link c-btn--small c-btn--simple js-notification-center__toggle" aria-expanded="true">
            <i class="c-btn__icon" aria-hidden="true" role="img"></i>
            <span class="u-text-brand c-btn__text">{{ "notification.close" | translate }}</span>
        </button>
    </header>

    <article *ngIf="notifications?.length === 0" class="c-notification-center__item">
        <div class="c-notification-center__item__body">
            <span class="u-text-base" style="font-weight: 100;">{{"noAlert" | translate}}</span>
        </div>
    </article>

    <article 
        *ngFor="let notification of notifications" 
        class="c-notification-article c-notification-center__item u-bg-white u-p-xsmall@s"
        [innerHTML]="notification.text"
    ></article>
</section>

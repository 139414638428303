import { DOCUMENT } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, Renderer2 } from "@angular/core";

@Component({
    selector: 'app-content-layout',
    templateUrl: './content-layout.component.html',
    //changeDetection: ChangeDetectionStrategy.OnPush
  })
  
  export class ContentLayoutComponent {

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2, 
        private cdr: ChangeDetectorRef,
    ){}
  }

import { createReducer, on } from "@ngrx/store";
import { GroundingDto } from "src/app/core/models/grounding-dto";
import * as GroundingSelectedActions from "./grounding-selected.actions";

export interface State {
  details: GroundingDto;
  isFetching: boolean;
  fetched: boolean;
}

export const initialState: State = {
  details: null,
  fetched: false,
  isFetching: false,
};

export const groundingSelectedReducer = createReducer(
  initialState,

  on(GroundingSelectedActions.LoadGroundingById, (state, action) => ({
    ...initialState,
    isFetching: true,
  })),
  on(GroundingSelectedActions.LoadGroundingByIdSuccess, (state, action) => ({
    ...state,
    details: action.record,
    isFetching: false,
  })),
  on(GroundingSelectedActions.LoadGroundingByIdFailure, (state) => ({
    ...state,
    isFetching: false,
  })),
  on(GroundingSelectedActions.ToggleGroundingPreference, (state, action) => {
    return state;
  }),
  on(
    GroundingSelectedActions.ToggleGroundingPreferenceSuccess,
    (state, action) => {
      if (!state.details) {
        return state;
      }

      const vehicle = new GroundingDto(state.details);
      let option = vehicle.getFirstOption;
      if (option) {
        option = { ...option, status: option.status === 1 ? 8 : 1 };
      } else {
        option = { status: 1 };
      }

      vehicle.firstOptions = [option];

      return {
        ...state,
        details: vehicle,
      };
    }
  )
);

import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromGrounding from "./grounding-returned-selected.reducer";

export const selectGroundingReturnedSelectedState =
  createFeatureSelector<fromGrounding.State>("selectedGroundingReturned");

export const selectGroundingReturnedSelected = createSelector(
  selectGroundingReturnedSelectedState,
  (state) => state.details
);

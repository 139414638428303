import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, concatMap, mergeMap, catchError, switchMap } from 'rxjs/operators';

import * as UserActions from './user.actions';
import { UserService } from 'src/app/core/services/api-wrapped/user.service';
import { of } from 'rxjs';
import { ShowNotifyMessage } from '../layout/layout.actions';
import { Router } from '@angular/router';
import { initializeUserForm, logout, passwordChanged } from '../auth/auth.actions';



@Injectable()
export class UserEffects {

  saveUserCompany$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(UserActions.SaveUserCompany),
      concatMap(action => 
        this.userService.saveUserCompany(action.data).pipe(
          mergeMap(res => {
            return res.level === 'error' ?
              of(UserActions.SaveUserCompanyFailure({ 
                error: {
                  name: "Error",
                  message: res.message 
                }
              })): 
              [UserActions.SaveUserCompanySuccess(), ShowNotifyMessage({message: res.message}), initializeUserForm({withProfileData: true})]}),
          catchError(error => of(UserActions.SaveUserCompanyFailure({ error })))
        )
      )
    );
  });

  saveUserPassword$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(UserActions.SaveUserPassword),

      concatMap(action => 
        this.userService.saveUserPassword(action.data).pipe(
          mergeMap(res => {
            if(res.level === 'error') {
              return of(UserActions.SaveUserPasswordFailure({ 
                error: {
                  name: "Error",
                  message: res.message 
                }
              }));
            } else {
              if(localStorage.getItem('passwordExpired') === 'true') {
                return [ShowNotifyMessage({message: res.message}), logout()];
              }
              return [passwordChanged(), UserActions.SaveUserPasswordSuccess(), ShowNotifyMessage({message: res.message})];
            }
          }),
          catchError(error => of(UserActions.SaveUserPasswordFailure({ error })))
        )
      )
    );
  });

  SaveUserProfile$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(UserActions.SaveUserProfile),
      concatMap(action => 
        this.userService.saveUserProfile(action.data).pipe(
          mergeMap(res => {
            return res.level === 'error' ?
              of(UserActions.SaveUserProfileFailure({ 
                error: {
                  name: "Error",
                  message: res.message 
                }
              })): 
              [UserActions.SaveUserProfileSuccess(), ShowNotifyMessage({message: res.message})]
            }),
          catchError(error => of(UserActions.SaveUserProfileFailure({ error })))
        )
      )
    );
  });


  addUserAccount$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(UserActions.AddUserAccount),
      concatMap(action => 
        this.userService.addUserAccount(action.data).pipe(
          mergeMap(res => {
            return res.level === 'error' ?
              of(UserActions.AddUserAccountFailure({ 
                error: {
                  name: "Error",
                  message: res.message 
                }
              })): 
              [UserActions.AddUserAccountSuccess(), ShowNotifyMessage({message: res.message})/*, initializeUserForm({withProfileData: true})*/]}),
          catchError(error => of(UserActions.AddUserAccountFailure({ error })))
        )
      )
    );
  });


  DownloadAgencyFormPdf$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.DownloadAgencyFormPdf),
      switchMap(action => this.userService.downloadAgencyFormPdf(action.data)),
      map(response => {
        const blob = new Blob([response?.body], { type: response?.body?.type });
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = 'agency.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);
        return UserActions.DownloadAgencyFormPdfSuccess();
      }),
      catchError(error => of(UserActions.AddUserAccountFailure({ error })))
    )
  });


  constructor(
    private actions$: Actions,
    private userService: UserService,
    private router: Router
  ) {}

}

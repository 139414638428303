import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { setLanguage } from './features/auth/auth.actions';
import { selectLayoutState } from './features/layout/layout.selectors';

@Component({
  selector: 'app-root',
  template: `<app-toast-notify *ngIf="message" [level]="level" [message]="message"></app-toast-notify><div class="o-root"><router-outlet></router-outlet></div>`,
  styles: []
})
export class AppComponent  implements OnInit , OnDestroy {
  private subscriptions: Subscription[] = [];
  public message: string;
  public level: string;

  constructor(
    private store: Store<{}>
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.select(selectLayoutState).subscribe(state => {
        this.message = state?.toastNotifyMessage;
        this.level = state?.toastNotifyLevel;
      })
    );

    if(localStorage.getItem('languageCode')) {
      this.store.dispatch(setLanguage({languageCode: localStorage.getItem('languageCode')}));
    }     
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

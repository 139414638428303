import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Routes } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectSecurity} from 'src/app/features/auth/auth.selectors';
import { ClearFormErrors } from 'src/app/features/user/user.actions';

@Component({
  selector: 'app-user-container',
  template: `<app-tabs-layout [routes]="childrenRoutes"></app-tabs-layout>`
})
export class UserContainerComponent implements OnInit {
  public childrenRoutes: Routes = [];
  
  constructor(
    private store: Store<{}>,
    private router: Router,
    public route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.router.events.subscribe(evt => { 
      if(evt instanceof NavigationEnd) {
        this.store.dispatch(ClearFormErrors());
      }
    });

    this.store.select(selectSecurity).subscribe(security => {
      this.childrenRoutes = this.route.snapshot.routeConfig.children.filter(route => {
        // Hide support route
        if (route.path === 'support') {
          return false;
        }

        if(!route.data)
          return true;

        if(route.data?.main_account) {
          return security.main_account;
        }

        if(route.data?.rmk_createaccount) {
          return route.data.rmk_createaccount === security.attributes.rmk_createaccount;
        }
        return true;
      });
    });
  }

}

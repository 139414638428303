import {
    FormGroup
} from "@angular/forms";

export function mapFormWithPatchValues(form: FormGroup, values:any){
    if(!values) return;
    var model = form.getRawValue();
    Object.keys(model).forEach(key => {
        let data = model[key] == null || typeof model[key] === "object" || typeof model[key] === "boolean" || typeof model[key] === "number" ? null : ''; //null
        if(values[key] != null && values[key] != undefined) {
            data = values[key];
        }
        form.get(key).patchValue(data, { emitEvent: false });
    });
}

export const groupByKey = (list: any, key: any) => list.reduce((hash: any, obj: any) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {});

export function isEqual(k1: any, k2: any) {
    let sk1 = JSON.stringify(k1);
    let sk2 = JSON.stringify(k2);
    return sk1 === sk2;
}

export function deepClone<T>(data:T):T{
    if(data===null || data === undefined) return null;
    return JSON.parse(JSON.stringify(data));
}

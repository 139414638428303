import {Component} from '@angular/core';
import {environment} from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent{
    public apiUrl = environment.apiUrl;
    public supportDocs = [
        { label: 'contract', path: 'contractPath' },
        { label: 'useGuide', path: 'useGuidePath' },
        { label: 'userStatusManual', path: 'userStatusManualPath' },
    ];

    public infoDocs = [
        { label: 'dieselEa189', useApiUrl: true, path: 'dieselEa189Path' },
        { label: 'privacy', useApiUrl: true, path: 'privacyPath' },
        { label: 'cookies', useApiUrl: true, path: 'cookiesPath' }
    ];
}

import { createReducer, on } from '@ngrx/store';
import { BuyerData, BuyerMeta, NewsInfo, Preferences, ProfileFormInfo, Register } from 'src/app/core/models/user-dto';
import * as AuthActions from './auth.actions';

export const authFeatureKey = 'auth';
export const DEFAULT_LANGUAGE_CODE = 'it';

interface Status {
  isFetching: boolean;
  error: any;
  success: boolean;
}

interface ResetPasswordStatus extends Status {
  successMessage: string;
  pinSent: boolean;
}

interface SignupSuccessStatus extends Status {
  registrationId: number;
}

const getDataFromLocalStorage = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (e) {
    return null;
  }
};

export interface State {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  passwordExpired: boolean;
  newsInfo: NewsInfo;
  buyerData: BuyerData;
  profileForm: ProfileFormInfo;
  preferences: Preferences;
  register: Register;
  language: string;
  loginStatus: Status;
  resetPasswordStatus: ResetPasswordStatus;
  signupStatus: Status;
  signupSuccessStatus: SignupSuccessStatus;
  acceptTermStatus: Status;
  meta: BuyerMeta;
  formReady: boolean;
  privacyFormStatus: Status;
}

export const initialState: State = {
  id: parseInt(localStorage.getItem('userId')),
  firstName: localStorage.getItem('firstname'),
  lastName: localStorage.getItem('lastname'),
  username: localStorage.getItem('username'),
  passwordExpired: localStorage.getItem('passwordExpired') === 'true' || false,
  newsInfo: {
    unread: parseInt(localStorage.getItem('newsInfo[unread]')) || 0,
    surveyUri: null,
    surveys: 0
  },
  meta: {
    security: {
      attributes: {
        rmk_createaccount: localStorage.getItem('meta[rmk_createaccount]') === 'true' || false,
        rmk_makeoffer: localStorage.getItem('meta[rmk_makeoffer]') === 'true' || false,
        rmk_receivecom: localStorage.getItem('meta[rmk_receivecom]') === 'true' || false,
        rmk_codice_as400: ''
      },
      enabled: null,
      grounding_enabled: localStorage.getItem('meta[grounding_enabled]') === 'true' || false,
      main_account: localStorage.getItem('meta[main_account]') === 'true' || false
    }
  },
  language: localStorage.getItem('languageCode') ? localStorage.getItem('languageCode') : DEFAULT_LANGUAGE_CODE,
  buyerData: getDataFromLocalStorage('buyerData'),
  profileForm: null,
  preferences: null,
  register: null,
  loginStatus: {
    isFetching: false,
    error: null,
    success: false
  },
  resetPasswordStatus: {
    isFetching: false,
    error: null,
    success: false,
    successMessage: null,
    pinSent: false
  },
  acceptTermStatus: {
    isFetching: false,
    error: null,
    success: false
  },
  signupStatus: {
    isFetching: false,
    error: null,
    success: false
  },
  signupSuccessStatus: {
    isFetching: false,
    error: null,
    success: false,
    registrationId: null
  },
  formReady: false,
  privacyFormStatus: {
    isFetching: false,
    error: null,
    success: false
  }
};


export const authReducer = createReducer(
  initialState,

  on(AuthActions.loginRequest, state => ({
    ...state,
    ...{ 
      loginStatus: {
        isFetching: true,
        success: false,
        error: null
      }
    }
  })),
  on(AuthActions.loginSuccess, (state, action) => ({
    ...state,
    ...{
      id: action.data.id,
      firstName: action.data.firstname,
      lastName: action.data.lastname,
      username: action.data.username,
      passwordExpired: action.data.passwordExpired,
      newsInfo: action.data.newsInfo,
      buyerData: action.data.buyer,
      meta: {
        security:  action.data.meta.security
      },
      loginStatus: {
        success: true,
        error: null,
        isFetching: false
      }
    }
  })),
  on(AuthActions.loginFailure, (state, action) => ({
    ...state,
    ...{ 
      loginStatus: {
        isFetching: false,
        success: false,
        error: action.error
      }
    }
  })),
  on(AuthActions.signupRequest, state => ({
    ...state,
    ...{ 
      signupStatus: {
        isFetching: true,
        success: false,
        error: null
      }
    }
  })),
  on(AuthActions.signupSuccess, (state, action) => ({
    ...state,
    ...{
      signupStatus: {
        isFetching: false,
        success: true,
        error: null
      },
      signupSuccessStatus: {
        isFetching: false,
        success: true,
        error: null,
        registrationId: action.data.registration_id
      }
    }
  })),
  on(AuthActions.signupFailure, (state, action) => ({
    ...state,
    ...{ 
      signupStatus: {
        isFetching: false,
        success: false,
        error: action.error
      }
    }
  })),
  on(AuthActions.forgotPasswordRequest, state => ({
    ...state,
    ...{ 
      resetPasswordStatus: {
        isFetching: true,
        success: false,
        error: null,
        successMessage: null,
        pinSent: false
      }
    }
  })),
  on(AuthActions.forgotPasswordSuccess, (state, action) => ({
    ...state,
    ...{
      resetPasswordStatus: {
        success: true,
        error: null,
        isFetching: false, 
        successMessage: action.data.message,
        pinSent: true
      }
    }
  })),
  on(AuthActions.forgotPasswordFailure, (state, action) => ({
    ...state,
    ...{ 
      resetPasswordStatus: {
        isFetching: false,
        success: false,
        error: action.error,
        successMessage: null,
        pinSent: false
      }
    }
  })),
  on(AuthActions.forgotPasswordVerifyPinRequest, state => ({
    ...state,
    ...{ 
      resetPasswordStatus: {
        ...state.resetPasswordStatus,
        ...{
          isFetching: true,
          success: false,
          error: null
        }
      }
    }
  })),
  on(AuthActions.forgotPasswordVerifyPinSuccess, (state, action) => ({
    ...state,
    ...{
      resetPasswordStatus: {
        ...state.resetPasswordStatus,
        ...{
          success: true,
          error: null,
          isFetching: false, 
          successMessage: action.data.message,
        }
      }
    }
  })),
  on(AuthActions.forgotPasswordVerifyPinFailure, (state, action) => ({
    ...state,
    ...{ 
      resetPasswordStatus: {
        ...state.resetPasswordStatus,
        ...{
          success: false,
          error: action.error,
          isFetching: false, 
          successMessage: null,
        }
      }
    }
  })),
  on(AuthActions.logout, (state, action) => initialState),
  on(AuthActions.setLanguage, (state, action) => ({
    ...state,
    ...{
      language: action.languageCode
    }
  })),
  on(AuthActions.AcceptTerms, (state, action) => ({
    ...state,
    ...{
      acceptTermStatus: {
        error: null,
        success: false,
        isFetching: true
      }
    }
  })),
  on(AuthActions.AcceptTermsSuccess, (state, action) => ({
    ...state,
    ...{
      acceptTermStatus: {
        error: null,
        success: true,
        isFetching: false
      }
    }
  })),
  on(AuthActions.initializeUserForm, (state, action) => ({
    ...state,
    ...{
      formReady: false
    }
  })),
  on(AuthActions.AcceptTermsSuccess, state => ({
    ...state,
    ...{
      preferences: {
        hide_toc: {
          type: 'boolean',
          value: '1'
        }
      }
    }
  })),
  on(AuthActions.initializeUserFormSuccess, (state, action) => ({
    ...state,
    ...{
      buyerData: action.data.buyerData,
      register: action.data.register,
      profileForm: action.data.profileForm,
      preferences: action.data.preferences,
      formReady: true
    }
  })),

  on(AuthActions.passwordChanged, state => ({
    ...state,
    ...{
      passwordExpired: false
    }
  })),
  
  on(AuthActions.markNewsAsRead, state => ({
    ...state,
    ...{
      newsInfo: {
        ...state.newsInfo,
        ...{
          unread: 0
        }
      }
    }
  })),


    on(AuthActions.PrivacyUpdate, (state, action) => ({
        ...state,
        ...{
          buyerData: {
            ...state.buyerData,
            ...{
              privacy: action.data
            }
          },
          privacyFormStatus: {
            isFetching: true,
            error: null,
            success: false
          }
        }
      })),


    on(AuthActions.PrivacyUpdateTerminated, (state, action) => ({
      ...state,
      ...{
        privacyFormStatus: {
          isFetching: false,
          error: null,
          success: action.success
        }
      }
    }))
);


<app-navbar ></app-navbar>
<main class="o-main">
  <router-outlet></router-outlet>
  <div class="c-back-to-top  js-back-to-top" *ngIf="isScrollTopVisible">
    <div class="c-back-to-top__pagewrap">
        <button (click)="scrollToTop()" class="c-btn  c-btn--secondary  c-btn--icon  c-back-to-top__trigger  js-back-to-top-trigger" type="button">
            <i class="c-back-to-top__icon" aria-hidden="true" role="img"></i>
            <span class="c-back-to-top__text">Back to Top</span>
        </button>
  
    </div>
  </div>
</main>
<app-footer *ngIf="isFooterVisible"></app-footer>

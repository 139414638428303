import { createAction, props } from '@ngrx/store';
import { VehicleModelDto } from 'src/app/core/models/veicle-model-dto';

export const LoadFavorites = createAction(
  '[Favorites] Load Favorites',
  props<{refresh?: boolean }>()
);
export const LoadFavoritesSuccess = createAction(
  '[Favorites] Load Favorites Success',
  props<{records: VehicleModelDto[]}>()
);
export const LoadFavoritesFailure = createAction(
  '[Favorites] Load Favorites Failure'
);

export const AddFavorite = createAction(
  '[Favorites] Add Favorites',
  props<{id: number}>()
);
export const AddFavoriteSuccess = createAction(
  '[Favorites] Add Favorite Success'
);
export const AddFavoriteFailure = createAction(
  '[Favorites] Add Favorite Failure'
);

export const RemoveFavorite = createAction(
  '[Favorites] Remove Favorite',
  props<{id: number}>()
);
export const RemoveFavoriteSuccess = createAction(
  '[Favorites] Remove Favorite Success',
  props<{id: number}>()
);
export const RemoveFavoriteFailure = createAction(
  '[Favorites] Remove Favorite Failure'
);

import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import * as GroundingReturnedActions from "./grounding-returned.actions";
import { GroundingService } from "src/app/core/services/api-wrapped/grounding.service";
import { Store } from "@ngrx/store";
import { selectGroundingReturnedState } from "./grounding-returned.selectors";

@Injectable()
export class GroundingReturnedEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _groundingService: GroundingService
  ) {}

  loadGroundingReturned$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroundingReturnedActions.LoadGroundingReturned),
      concatLatestFrom((action) =>
        this._store.select(selectGroundingReturnedState)
      ),
      switchMap(([action, store]) =>
        this._groundingService.GetsReturned({page: store.page, filters: store.filters})
      ),
      switchMap((records) =>
        records.length > 0
          ? [GroundingReturnedActions.LoadGroundingReturnedSuccess({ records })]
          : [GroundingReturnedActions.LoadGroundingReturnedFailure()]
      )
    )
  );

  setGroundingReturnedNextPage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroundingReturnedActions.SetGroundingReturnedNextPage),
      map(action => GroundingReturnedActions.LoadGroundingReturned())
    )
  );

  ApplyGroundingReturnedFilters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroundingReturnedActions.ApplyGroundingReturnedFilters),
      map(action => GroundingReturnedActions.LoadGroundingReturned())
    )
  );

  resetGroundingReturnedFilters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroundingReturnedActions.ResetGroundingReturnedFilters),
      map(action => GroundingReturnedActions.LoadGroundingReturned())
    )
  );
}

import { createReducer, on } from '@ngrx/store';
import { GalleryWithVehicleId } from 'src/app/core/models/vehicle-dto';
import * as GalleryActions from './vehicle-gallery.actions';

export const galleryFeatureKey = 'gallery';
export const DEFAULT_LANGUAGE_CODE = 'it';

export interface State {
  isFetching: boolean;
  error: any;
  success: boolean;
  activeVehicleId: number;
  title: string;
  galleries: GalleryWithVehicleId[];
}

export const initialState: State = {
  isFetching: false,
  error: null,
  success: false,
  activeVehicleId: null,
  title: null,
  galleries: []
};


export const galleryReducer = createReducer(
  initialState,

  on(GalleryActions.OpenVehicleGallerySuccess, (state, action) => ({
    ...state,
    ...{
      isFetching: false,
      success: false,
      activeVehicleId: action.gallery.vehicleId,
      title: action.title,
      // Rimpiazzo la gallery se già esistente, altrimenti la aggiungo
      galleries: state.galleries.filter(gallery => gallery.vehicleId !== action.gallery.vehicleId).concat(action.gallery)
    }
  })),

  on(GalleryActions.CloseVehicleGallery, (state, action) => ({
    ...state,
    activeVehicleId: null,
    title: null
  }))

);


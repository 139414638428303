import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import * as GroundingSelectedActions from "./grounding-selected.actions";
import { GroundingService } from "src/app/core/services/api-wrapped/grounding.service";
import { of } from "rxjs";
import { ToggleGroundingPreferenceListSuccess } from "../grounding/grounding.actions";
import { selectGroundingSelectedState } from "./grounding-selected.selectors";
import { Store } from "@ngrx/store";
import { Location } from "@angular/common";

@Injectable()
export class GroundingSelectedEffects {
  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _location: Location,
    private _groundingService: GroundingService
  ) {}

  toggleGroundingPreference$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(GroundingSelectedActions.ToggleGroundingPreference),
      switchMap((action) =>
        this._groundingService.TogglePreference(action.id).pipe(
          mergeMap((res) => [
            ToggleGroundingPreferenceListSuccess({
              id: action.id,
            }),
            GroundingSelectedActions.ToggleGroundingPreferenceSuccess(),
          ]),
          catchError((err) =>
            of(GroundingSelectedActions.ToggleGroundingPreferenceFailure())
          )
        )
      )
    );
  });

  loadGroundingById$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroundingSelectedActions.LoadGroundingById),
      concatLatestFrom((action) =>
        this._store.select(selectGroundingSelectedState)
      ),
      switchMap(([action, store]) =>
        this._groundingService.GetById(action.id).pipe(
          mergeMap((res) => [
            GroundingSelectedActions.LoadGroundingByIdSuccess({
              record: res,
            }),
          ]),
          catchError((err) => {
            this._location.back();
            return of(GroundingSelectedActions.LoadGroundingByIdFailure());
          })
        )
      )
    )
  );
}

<h4 class="u-mb-small">
    {{'footer.support' | translate}}
</h4>

<ul class="c-footer-nav__list">
    <li class="">
        <button class="c-btn  c-btn--link  c-btn--round  c-btn--small" data-tr-component="Button Link">
            <i class="c-btn__icon c-icon c-icon--[semantic-phone]" aria-hidden="true" role="img"></i>
            <span class="c-btn__text">
                Interlogica s.r.l. (041) 5354800
            </span>
        </button>
    </li>
    <li class="u-mt-small">
        <a href="mailto:assistenza@interlogica.it" class="c-btn  c-btn--link  c-btn--round  c-btn--small"
           data-tr-component="Button Link">
            <i class="c-btn__icon c-icon c-icon--[semantic-mail]" aria-hidden="true" role="img"></i>
            <span class="c-btn__text">
                assistenza@interlogica.it
            </span>
        </a>
    </li>
</ul>

<div class="c-footer-nav__title  js-footer-accordion__title u-mt-large">
    <h4>
        {{'footer.docsSupport' | translate}}
    </h4>
</div>
<ul class="c-footer-nav__list">
    <li *ngFor="let doc of supportDocs" class="c-footer-nav__item">
        <a [href]="apiUrl + ('footer.docs.' + doc.path | translate)" target="_blank" rel="noopener">
            {{'footer.docs.' + doc.label | translate}}
        </a>
    </li>
</ul>

<div class="c-footer-nav__title  js-footer-accordion__title u-mt-large">
    <h4>
        {{'footer.docsInfo' | translate}}
    </h4>
</div>
<ul class="c-footer-nav__list">
    <li *ngFor="let doc of infoDocs" class="c-footer-nav__item">
        <a [href]="(doc.useApiUrl ? apiUrl : '') + ('footer.docs.' + doc.path | translate)" target="_blank" rel="noopener">
            {{'footer.docs.' + doc.label | translate}}
        </a>
    </li>
</ul>

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { logout } from 'src/app/features/auth/auth.actions';
import { ShowNotifyMessage } from 'src/app/features/layout/layout.actions';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private store: Store<{}>
  ){}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token = this.authService.getToken(); //Aggiungere service per la gestione dell'utente
    
    if(token){
      req = req.clone({
          setHeaders: {
              'Authorization': `Bearer ${token}`
          }
      });
    }

    return next.handle(req).pipe(
      map(res => {
        if(res instanceof HttpResponse && res.body?.errors?.length > 0) { 
          throw new Error(res.body?.errors.join());
        } else {
          return res;
        }
      }),
      catchError(error => {
        switch (error.status) {
          case 401:
            this.store.dispatch(logout());
            break;
          case 403:
            this.store.dispatch(ShowNotifyMessage({
              message: 'Generic error',
              level: 'error'
            }));
            break;
        }
        return throwError(error);
      })
    );
  }
}

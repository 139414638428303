import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const selectAuthState = createFeatureSelector<fromAuth.State>(
  fromAuth.authFeatureKey
);

export const selectLoggedUserHeaderInfo = createSelector(
  selectAuthState, 
  (state) => ({
    fullName: (state.firstName ? (state.firstName + " ") : "") + (state.lastName ? (state.lastName) : ""),
    company: state.buyerData?.ragionesociale
  })
)

export const selectHideToc = createSelector(
  selectAuthState, state => state.preferences?.hide_toc?.value === "1"
)

export const selectCounterNotifications = createSelector(
  selectAuthState, state => state.newsInfo.unread
)

export const selectBuyerPermissions = createSelector(
  selectAuthState, state => state.meta.security.attributes
)

export const selectSecurity = createSelector(
  selectAuthState, state => state.meta.security
);

export const selectCurrentLanguage = createSelector(
  selectAuthState, state => state.language
);
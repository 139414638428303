import { createReducer, on } from '@ngrx/store';
import { AuctionDto } from 'src/app/core/models/auction-dto';
import * as AuctionSelectedActions from './auction-selected.actions';

export interface State {
  modalAction: 'deleteOffer' | 'buyNow' | 'makeOffer' | 'distributeValues' | null
  auctionDetail: AuctionDto;
  offerAmount: number;
  isFetching: boolean;
  step: number;
  directOffer: boolean;
  errors: string;
  success: boolean;
  visited: boolean;
}

export const initialState: State = {
  modalAction: null,
  auctionDetail: null,
  offerAmount: 0,
  isFetching: false,
  step: 0,
  directOffer: false,
  errors: null,
  success: false,
  visited: false
};

export const auctionSelectedReducer = createReducer(
  initialState,

  on(AuctionSelectedActions.LoadAuctionById, (state, action) => ({
    ...initialState,
    ...{
      isFetching: true
    }
  })),
  on(AuctionSelectedActions.LoadAuctionByIdSuccess, (state, action) => ({
    ...state,
    ...{
      auctionDetail: action.record,
      isFetching: false,
      visited: true
    }
  })
  ),
  on(AuctionSelectedActions.TogglePreference, (state, action) => {
    return state;
  }),
  on(AuctionSelectedActions.TogglePreferenceSuccess, (state, action) => {
    const auction = new AuctionDto(state.auctionDetail);
    auction.isFavourite = !state.auctionDetail?.isFavourite;
    return {
      ...state,
      ...{
        auctionDetail: auction
      }
    }
  }),
  on(AuctionSelectedActions.OpenModalAction, (state, action) => {
    
    return {
      ...state,
      ...{
        offerAmount: action.amount,
        auctionDetail: action.auction,
        modalAction: action.modalAction,
        isFetching: false,
        step: initialState.step,
        errors: initialState.errors,
        success: false,
        visited: false
      }
    }}
  ),

  // Failure
  on(AuctionSelectedActions.MakeOfferFailure, AuctionSelectedActions.DeleteOfferFailure, (state, action) => ({
    ...state,
    ...{
      isFetching: false,
      errors: action.error,
      modalAction: null,
      success: false
    }
  })),
  on(AuctionSelectedActions.BuyNowFailure, AuctionSelectedActions.DistributeValuesFailure, (state, action) => ({
    ...state,
    ...{
      isFetching: false,
      errors: action.error,
      success: false
    }
  })),

  on(AuctionSelectedActions.OpenModalActionSuccess, (state, action) => {
    return {
      ...state,
      ...{
        step: action.step,
      }
    }}
  ),

  on(AuctionSelectedActions.CloseModalAction, (state, action) => ({
    ...state,
    ...{
      modalAction: null
    }
  })),

  on(AuctionSelectedActions.AcceptTerms, (state, action) => ({
    ...state,
    ...{
      step: state.step + 1
    }
  })),

  on(AuctionSelectedActions.MakeOffer, AuctionSelectedActions.DeleteOffer, AuctionSelectedActions.BuyNow, AuctionSelectedActions.DistributeValues, (state, action) => ({
    ...state,
    ...{
      isFetching: true,
      errors: null,
      success: false
    }
  })),
  on(AuctionSelectedActions.MakeOfferSuccess, (state, action) => ({
    ...state,
    ...{
      isFetching: false,
      modalAction: null,
      auctionDetail: Object.assign(new AuctionDto(), {
        ...state.auctionDetail,
        ...{
          actualOffer: action.auction.actualOffer,
          isWinner: action.auction.isWinner,
          isFavourite: true,
          lastOffer: {
            maxValue: action.auction.lastOffer.maxValue,
            total: action.auction.lastOffer.maxValue
          }
        }
      })
    }
  })),

  on(AuctionSelectedActions.DeleteOfferSuccess, (state, action) => ({
    ...state,
    ...{
      isFetching: false,
      modalAction: null,
      auctionDetail: Object.assign(new AuctionDto(), {
        ...state.auctionDetail,
        ...{
          actualOffer: action.auction.actualOffer,
          isWinner: action.auction.isWinner,
          lastOffer: {
            maxValue: action.auction.lastOffer.maxValue,
            total: action.auction.lastOffer.maxValue
          }
        }
      })
    }
  })),

  on(AuctionSelectedActions.BuyNowSuccess, (state, action) => ({
    ...state,
    ...{
      isFetching: false,
      modalAction: null,
      auctionDetail: Object.assign(new AuctionDto(), {
        ...state.auctionDetail,
        ...{
          actualOffer: action.auction.actualOffer,
          idstatoasta: action.auction.idstatoasta,
          astaFineDataora: action.auction.astaFineDataora,
          isWinner: action.auction.isWinner,
          lastOffer: {
            maxValue: action.auction.lastOffer.maxValue,
            total: action.auction.lastOffer.maxValue
          }
        }
      })
    }
  })),


  on(AuctionSelectedActions.DistributeValuesSuccess, (state, action) => ({
    ...state,
    ...{
      isFetching: false,
      modalAction: state.modalAction === 'distributeValues' ? null: state.modalAction,
      step: state.modalAction === 'distributeValues' ? 1: state.step+1,
      auctionDetail: Object.assign(new AuctionDto(), {
        ...state.auctionDetail,
        ...{
          lastOffer: {
            ...state.auctionDetail.lastOffer,
            ...{
              vehicles: action.distribution
            }
          }
        }
      })
    }
  })),

  on(AuctionSelectedActions.SetDirectOffer, (state, action) => ({
    ...state,
    ...{
      auctionDetail: action.auction, // superfluo finchè viene chiamato dal dettaglio asta
      directOffer: action.directOffer
    }
  })),

  on(AuctionSelectedActions.GetAuctionPriceDetailSuccess, (state, action) => {
    const updatedAuction = action.priceList.find(auc => auc.id === state.auctionDetail.id);
    if(!updatedAuction) {
      return state;
    }
    return {
      ...state,
      ...{
        auctionDetail: Object.assign(new AuctionDto(), {
          ...state.auctionDetail,
          ...{
            actualOffer: updatedAuction.actualOffer,
            idstatoasta: updatedAuction.idstatoasta,
            isWinner: updatedAuction.isWinner,
            lastOffer: {
              ...state.auctionDetail.lastOffer,
              ...{
                maxValue: updatedAuction.lastOffer.maxValue
              }
            }
          }
        })
      }
    }
  }),

  on(AuctionSelectedActions.GenerateVehicleZipSuccess, (state, action) => ({
    ...state,
    ...{
      auctionDetail: Object.assign(new AuctionDto(), {
        ...state.auctionDetail,
        ...{
          vehicles: state.auctionDetail.vehicles.map(v => ({
            ...v, 
            ...{
              hasZip: action.vehicleId === v.id ? true : v.hasZip
            }
          })
          )
        }
      })
    }
  }))
);


import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Routes } from '@angular/router';

@Component({
  selector: 'app-tabs-layout',
  templateUrl: './tabs-layout.component.html',
  styleUrls: ['./tabs-layout.component.scss']
})
export class TabsLayoutComponent implements OnInit {
  @Input() routes: Routes;
  public childrenRoutes: Routes = [];
  public opened = false;

  constructor(
    private router: Router, 
    public route:ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.childrenRoutes = this.routes ?? this.route.snapshot.routeConfig.children;
  }

}

import { createAction, props } from "@ngrx/store";
import { Notification } from "src/app/core/models/notification-dto";

export const GetNotifications = createAction(
  '[Notification] Get Notifications'
);
export const GetNotificationSuccess = createAction(
  '[Notification] Get Notifications Success',
  props<{notifications: Notification[]}>()
);
export const GetNotificationFailure = createAction(
  '[Notification] Get Notifications Failure',
  props<{error: string}>()
);

export const ToggleNotificationsMenu = createAction(
  '[Notification] Toggle Notifications Menu'
);
export const ToggleNotificationsMenuCompleted = createAction(
  '[Notification] Toggle Notifications Menu Completed'
);

export const MarksAsReadNotifications = createAction(
  '[Notification] Marks As Read Notifications'
);
export const MarksAsReadNotificationsSuccess = createAction(
  '[Notification] Marks As Read Notifications Success'
);
export const MarksAsReadNotificationsFailure = createAction(
  '[Notification] Marks As Read Notifications Failure',
  props<{error: string}>()
);
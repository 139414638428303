export class GroundingDto {
  constructor(obj?: GroundingDto) {
    Object.assign(this, obj);
  }

  acquirente: string;
  appendice: number;
  bankCode: string;
  cliente: string;
  companyName: string;
  concessionario: number;
  createdAt: string;
  deletedAt: string;
  description: string;
  firstOptions: any[];
  iban: string;
  id: number;
  idazi: number;
  importo_patto: number;
  make: string;
  model: string;
  patto_riacquist: string;
  plate: string;
  prodotto: string;
  scheda: number;
  sogg_riscat: number;
  stato_appendice: string;
  stato_hskg: string;
  tipo_prodotto: string;
  updatedAt: string;
  valore_blu_rett: number;
  valore_residuo: number;
  vendita_data: string;
  vendita_fatt: string;
  vendita_imp: string;
  verifiedAt: string;
  version: string;

  get getFirstOption(): any {
    if (!this.firstOptions?.length) {
      return null;
    }

    return this.firstOptions[this.firstOptions.length - 1];
  }

  get getWonStatus(): string {
    return this.getFirstOption.status === 5 ? "proposalAccepted" : "proposalRejected";
  }

  get icon(): string {
    return this.getFirstOption.status === 5 ? "thumb-up.svg" : "thumb-down.svg";
  }
}

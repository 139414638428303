import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';

import * as NotificationActions from './notification.actions';
import { ShowNotifyMessage } from '../layout/layout.actions';
import { selectNotificationState } from './notification.selectors';
import { State } from './notification.reducer';
import { Store } from '@ngrx/store';
import { NotificationService } from 'src/app/core/services/api-wrapped/notification.service';
import { of } from 'rxjs';
import { selectAuthState } from '../auth/auth.selectors';
import { selectAuctionState } from '../auction/auction.selectors';
import { selectAuctionSelectedState } from '../auctionSelected/auction-selected.selectors';



@Injectable()
export class NotificationEffects {
  
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private notificationService: NotificationService
  ) {}

  getNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationActions.GetNotifications),
      concatLatestFrom(_ => this.store.select(selectNotificationState)),
      concatLatestFrom(([action, store]) => this.store.select(selectAuthState).pipe(
        map(auth => ({
          menuOpened: store.menuOpened,
          notifications: store.notifications,
          passwordExpired: auth.passwordExpired
        }))
      )),
      concatLatestFrom(([action, store]) => this.store.select(selectAuctionState).pipe(
        map(auctionsSlice => ({
          menuOpened: store.menuOpened,
          notifications: store.notifications,
          passwordExpired: store.passwordExpired,
          auctions: auctionsSlice.auctions
        }))
      )),
      concatLatestFrom(([action, store]) => this.store.select(selectAuctionSelectedState).pipe(
        map(auctionsSlice => ({
          menuOpened: store.menuOpened,
          notifications: store.notifications,
          passwordExpired: store.passwordExpired,
          auctions: store.auctions,
          auctionSelected: auctionsSlice.auctionDetail
        }))
      )),
      exhaustMap(([action, store]) => {
        // Se menu delle notifiche è aperto, skippo il ricaricamento delle notifiche
        const obs = (store.auctions?.length === 0 && !store.auctionSelected) || store.menuOpened || store.passwordExpired ? of(store.notifications) : this.notificationService.Gets();
        return obs.pipe(
          map(notifications => NotificationActions.GetNotificationSuccess({notifications})),
          catchError(err => [
            // ShowNotifyMessage({message: err.error, level: 'error'}),
            NotificationActions.GetNotificationFailure({error: err.error})
          ])
        )
      })
    )
  });

  openMenu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationActions.ToggleNotificationsMenu),
      concatLatestFrom(action => this.store.select(selectNotificationState)),
      map(([action, store]) => {
        return store.menuOpened && store.notifications.length > 0 ? NotificationActions.MarksAsReadNotifications() : NotificationActions.ToggleNotificationsMenuCompleted();
      })
    )
  });

  marksAsReadNotifications$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NotificationActions.MarksAsReadNotifications),
      switchMap(action => this.notificationService.MarksAsRead().pipe(
        map(notifications => NotificationActions.MarksAsReadNotificationsSuccess()),
        catchError(err => [
          ShowNotifyMessage({message: err.error, level: 'error'}),
          NotificationActions.MarksAsReadNotificationsFailure({error: err.error})
        ])
      ))
    )
  });

}

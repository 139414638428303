import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { catchError, exhaustMap, map, mergeMap, switchMap } from 'rxjs/operators';

import * as AuctionSelectedActions from './auction-selected.actions';
import { Store } from '@ngrx/store';
import { State } from './auction-selected.reducer';
import { AuctionService } from 'src/app/core/services/api-wrapped/auction.service';
import { selectAuctionSelectedState } from './auction-selected.selectors';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { forkJoin, of } from 'rxjs';
import { BuyNowSuccessList, DeleteOfferSuccessList, DistributeValuesListSuccess, GetAuctionPriceListSuccess, MakeOfferSuccessList, TogglePreferenceListSuccess } from '../auction/auction.actions';
import { AuthService } from 'src/app/core/services/auth.service';
import { StaticValuesService } from 'src/app/core/services/api-wrapped/static-values.service';
import { GetStaticValuesSuccess, ShowNotifyMessage } from '../layout/layout.actions';
import { selectLayoutState } from '../layout/layout.selectors';
import { selectAuthState } from '../auth/auth.selectors';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { formatCurrency } from '@angular/common';
import { AuctionDto } from 'src/app/core/models/auction-dto';
import { AcceptTermsSuccess } from '../auth/auth.actions';


@Injectable()
export class AuctionSelectedEffects {
  constructor (
    private actions$: Actions,
    private store: Store<State>,
    private auctionService: AuctionService,
    private authBaseService: AuthService,
    private staticValuesService: StaticValuesService,
    private gaService: GoogleAnalyticsService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  loadAuctionById$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(AuctionSelectedActions.LoadAuctionById),
      concatLatestFrom(action => this.store.select(selectLayoutState)),
      switchMap(([action, store]) => forkJoin([
        store.staticValues.fastBidStep ? of(store.staticValues) : this.staticValuesService.Get(),
        this.auctionService.GetById(action.id)
      ]).pipe(
        mergeMap(res => [
          GetStaticValuesSuccess({data: res[0]}),
          AuctionSelectedActions.LoadAuctionByIdSuccess({record: res[1]})
        ]),
        catchError(err => {
          this.router.navigate(['/pages/error']);
          return of(AuctionSelectedActions.LoadAuctionByIdFailure())
        })
      ))
    )}
  );

  togglePreference$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(AuctionSelectedActions.TogglePreference),
      switchMap(action => this.auctionService.TogglePreference(action.auction.id).pipe(
        mergeMap(res => [
          TogglePreferenceListSuccess({auctionId: action.auction.id}),
          AuctionSelectedActions.TogglePreferenceSuccess()
        ]),
        catchError(err => of(AuctionSelectedActions.TogglePreferenceFailure()))
      ))
    )
  });

  makeOffer$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(AuctionSelectedActions.MakeOffer),
      concatLatestFrom(action => this.store.select(selectAuctionSelectedState)),
      switchMap(([action, store]) => {
        this.gaService.event('modalOffer_ConfirmOffer');
        return this.auctionService.HandleOffer({
          auctionId: store.auctionDetail.id,
          offer: store.offerAmount,
          buynowValue: store.auctionDetail.buyNow,
          action: store.auctionDetail.isOpen ? 'bid' : 'makeOrModifyOffer',
          directOffer: store.directOffer
        }).pipe(
          mergeMap(res => {
            const getMessageValue = (): string => {
              if (!store.auctionDetail.isOpen) {
                return "status.OFFER_OK"
              }
              return res.isWinner ? "status.OFFER_OK_WINNING" : "status.OFFER_OK_NOT_WINNING";
            }

            const message = getMessageValue();
            const level = res.isWinner || !store.auctionDetail.isOpen ? 'success': 'error';
            return [
              AuctionSelectedActions.MakeOfferSuccess({auction: res}),
              MakeOfferSuccessList({auction: res}),
              ShowNotifyMessage({
                message: this.translateService.instant(message, {
                  value: formatCurrency(store.offerAmount, 'it', 'EUR')
                }),
                level
              })
            ]
          }),
          catchError(err => [
            ShowNotifyMessage({message: err.error, level: 'error'}),
            AuctionSelectedActions.MakeOfferFailure({error: err.error})
          ])
        )
      }),
      
    )
  });

  deleteOffer$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(AuctionSelectedActions.DeleteOffer),
      concatLatestFrom(action => this.store.select(selectAuctionSelectedState)),
      switchMap(([action, store]) => {
        this.gaService.event('modalOffer_DeleteOffer');
        return this.auctionService.HandleOffer({
          auctionId: store.auctionDetail.id,
          offer: store.offerAmount,
          buynowValue: store.auctionDetail.buyNow,
          action: 'withdrawOffer',
          directOffer: store.directOffer
        }).pipe(
          mergeMap(auction => [
            AuctionSelectedActions.DeleteOfferSuccess({auction}),
            DeleteOfferSuccessList({auction}),
            ShowNotifyMessage({
              message: this.translateService.instant("status.DELETE_OK"),
              level: 'success'
            })
          ]),
          catchError(err => [
            ShowNotifyMessage({message: err.error, level: 'error'}),
            AuctionSelectedActions.DeleteOfferFailure({error: err.error})
          ])
        )
      }),
      
    )
  });

  buyNow$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(AuctionSelectedActions.BuyNow),
      concatLatestFrom(action => this.store.select(selectAuctionSelectedState)),
      switchMap(([action, store]) => this.auctionService.HandleOffer({
        auctionId: store.auctionDetail.id,
        offer: store.auctionDetail.buyNow,
        buynowValue: store.auctionDetail.buyNow,
        action: 'buynow',
        directOffer: true
      }).pipe(
        mergeMap(res => {
          if(action.fromList) {
            this.router.navigate(['auction/detail/' + store.auctionDetail.id], {replaceUrl: true});
          }

          const actionDispatch = action.fromList ? BuyNowSuccessList({auction: res}) : AuctionSelectedActions.LoadAuctionById({id: store.auctionDetail.id});
          
          return [
            actionDispatch,
            ShowNotifyMessage({
              message: this.translateService.instant("status.BUY_NOW_OK", {
                value: formatCurrency(store.offerAmount, 'it', 'EUR')
              }),
              level: 'success'
            })
          ]
        }),
        catchError(err => [
          ShowNotifyMessage({message: err.error, level: 'error'}),
          AuctionSelectedActions.BuyNowFailure({error: err.error})
        ])
      ))
    )
  });

  distributeValues$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(AuctionSelectedActions.DistributeValues),
      concatLatestFrom(action => this.store.select(selectAuctionSelectedState)),
      switchMap(([action, store]) => this.auctionService.DistributeValues(store.auctionDetail.id, action.distribution).pipe(
        switchMap(res => [
          DistributeValuesListSuccess({auctionId: store.auctionDetail.id, distribution: action.distribution}),
          AuctionSelectedActions.DistributeValuesSuccess({distribution: action.distribution}),
          ShowNotifyMessage({
            message: this.translateService.instant("status.DISTRIBUTE_OK"),
            level: 'success'
          })
        ]),
        catchError(err => [
          ShowNotifyMessage({message: err.error, level: 'error'}),
          AuctionSelectedActions.DistributeValuesFailure({error: err.error})
        ])
      ))
    )
  });

  openModalAction$ = createEffect(() => {
    return this.actions$.pipe( 

      ofType(AuctionSelectedActions.OpenModalAction),
      concatLatestFrom(action => this.store.select(selectAuthState)),
      switchMap(([action, store]) => {
        if(['buyNow', 'makeOffer', 'deleteOffer'].indexOf(action.modalAction) > -1) {
          return store.preferences?.hide_toc?.value ? 
            of({action: action.modalAction, step: store.preferences?.hide_toc?.value === "1" ? 2 : 1}) : 
            this.authBaseService.userFormsData().pipe(
              map(res => ({action: action.modalAction, step: res?.preferences?.hide_toc?.value === "1" ? 2 : 1}))
            );
        } else {
          return of({action: action.modalAction, step: action.auction?.idstatoasta === 6 ? 1 : 2});
        }
      }),
      switchMap(res => {
        const actions = [];
        actions.push(AuctionSelectedActions.OpenModalActionSuccess({step: res.step}));

        //Aggiorno stato di preferences hide_toc
        if(res.step === 2 && ['buyNow', 'makeOffer', 'deleteOffer'].includes(res.action)) {
          actions.push(AcceptTermsSuccess());
        }
        return actions;
      }),
      catchError(err => of(AuctionSelectedActions.OpenModalActionFailure({error: err})))
    )
  })

  DownloadVehicleDoc$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuctionSelectedActions.DownloadVehicleDoc),
      switchMap(action => this.auctionService.DownloadVehicleDoc(action.vehicle, action.docType).pipe(
        map(response => {
          const blob = new Blob([response?.body], { type: response?.body?.type });
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = action.vehicle.targa + '.' + action.docType;
          a.click();
          URL.revokeObjectURL(objectUrl);
        })
      ))
    )
  }, { dispatch: false });

  DownloadAuctionDoc$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuctionSelectedActions.DownloadAuctionDoc),
      switchMap(action => this.auctionService.DownloadAuctionDoc(action.doc.id).pipe(
        map(response => {
          const blob = new Blob([response?.body], { type: response?.body?.type });
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = action.doc.filename;
          a.click();
          URL.revokeObjectURL(objectUrl);
        })
      )),
    )
  }, { dispatch: false });

  getAuctionPrice$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuctionSelectedActions.GetAuctionPriceDetail),
      concatLatestFrom(action => this.store.select(selectAuctionSelectedState)),
      exhaustMap(([action, store]) => {
        const ids = store.auctionDetail?.id ? [store.auctionDetail.id] : [];
        
        const auction = new AuctionDto(store.auctionDetail);
        const obs = auction.isFinished || ids.length === 0 ? of([]): this.auctionService.GetAuctionPriceList(ids);
        return obs.pipe(
          switchMap(res => [
            GetAuctionPriceListSuccess({priceList: res}),
            AuctionSelectedActions.GetAuctionPriceDetailSuccess({priceList: res})
          ]),
          catchError(err => [
            AuctionSelectedActions.GetAuctionPriceDetailFailure()
          ])
        )
      })
    )
  });

  generateVehicleZip$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuctionSelectedActions.GenerateVehicleZip),
      switchMap(action => {
        return this.auctionService.GenerateZip(action.vehicle.id).pipe(
          map(res => AuctionSelectedActions.GenerateVehicleZipSuccess({vehicleId: action.vehicle.id})),
          catchError(err => [
            AuctionSelectedActions.GenerateVehicleZipFailure(),
            ShowNotifyMessage({message: this.translateService.instant('genericError'), level: 'error'})
          ])
        )
      })
    )
  });
}

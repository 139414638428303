import { createAction, props } from "@ngrx/store";
import { VehicleDto } from "src/app/core/models/vehicle-dto";

export const LoadGroundingReturned = createAction(
  "[Grounding returned] Load Grounding returned"
);
export const LoadGroundingReturnedSuccess = createAction(
  "[Grounding returned] Load Grounding returned Success",
  props<{ records: VehicleDto[] }>()
);
export const LoadGroundingReturnedFailure = createAction(
  "[Grounding returned] Load Grounding returned Failure"
);
export const SetGroundingReturnedNextPage = createAction(
  "[Grounding returned] Next Page"
);
export const SetGroundingReturnedFilter = createAction(
  "[Grounding returned] Set Filter",
  props<{ filter: { [key: string]: any } }>()
);
export const ResetGroundingReturnedFilters = createAction(
  "[Grounding returned] Reset Filters"
);
export const ApplyGroundingReturnedFilters = createAction(
  "[Grounding returned] Apply Filters"
);
export const ToggleGroundingReturnedPreferenceListSuccess = createAction(
  "[Grounding] Toggle Preference List Success",
  props<{ id: number }>()
);
export const CleanGroundingReturnedListState = createAction(
  "[Grounding] Clean listing state",
);

import { createAction, props } from '@ngrx/store';
import {
    ForgotPasswordDto,
    ForgotPasswordResponseDto,
    ForgotPasswordVerifyPinDto,
    ForgotPasswordVerifyPinResponseDto,
    LoginDto,
    SignupDto,
    SignupResponseDto
} from 'src/app/core/models/auth-dto';
import {BuyerData, Preferences, Privacy, ProfileFormInfo, Register, User} from 'src/app/core/models/user-dto';

export const loginRequest = createAction(
  '[Auth] Login Request',
  props<{body: LoginDto}>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ data: User }>()
);

export const loginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: any }>()
);

export const signupRequest = createAction(
  '[Auth] Signup Request',
  props<{body: SignupDto}>()
);

export const signupSuccess = createAction(
  '[Auth] Signup Success',
    props<{data: SignupResponseDto}>()
);

export const signupFailure = createAction(
  '[Auth] Signup Failure',
  props<{ error: any }>()
);

export const forgotPasswordRequest = createAction(
  '[Auth] Forgot Password Request',
  props<{body: ForgotPasswordDto}>()
);

export const forgotPasswordSuccess = createAction(
  '[Auth] Forgot Password Success',
  props<{data: ForgotPasswordResponseDto}>()
);

export const forgotPasswordFailure = createAction(
  '[Auth] Forgot Password Failure',
  props<{ error: any }>()
);

export const forgotPasswordVerifyPinRequest = createAction(
  '[Auth] Forgot Password Verify Pin Request',
  props<{body: ForgotPasswordVerifyPinDto}>()
);

export const forgotPasswordVerifyPinSuccess = createAction(
  '[Auth] Forgot Password Verify Pin Success',
  props<{data: ForgotPasswordVerifyPinResponseDto}>()
);

export const forgotPasswordVerifyPinFailure = createAction(
  '[Auth] Forgot Password Verify Pin Failure',
  props<{ error: any }>()
);

export const initializeUserForm = createAction(
  '[Auth] Initialize User Form Request',
  props<{withProfileData?: boolean}>()
);

export const initializeUserFormSuccess = createAction(
  '[Auth] Initialize User Form Success',
  props<{ data: { buyerData: BuyerData, register: Register, profileForm: ProfileFormInfo, preferences: Preferences }}>()
);

export const initializeUserFormFailure = createAction(
  '[Auth] Initialize User Form Failure',
  props<{ error: any }>()
);

export const logout = createAction(
  '[Auth] Logout'
);

export const passwordChanged = createAction(
  '[Auth] Password Changed'
);

export const setLanguage = createAction(
  '[Auth] Set Language',
  props<{languageCode: string}>()
);

export const AcceptTerms = createAction(
  '[Auth] Accept Terms',
  props<{dispatchModalStep: boolean, hideToc: boolean}>()
);
export const AcceptTermsSuccess = createAction(
  '[Auth] Accept Terms Success'
);
export const AcceptTermsFailure = createAction(
  '[Auth] Accept Terms Failure',
  props<{ error: any }>()
);

export const markNewsAsRead = createAction(
  '[Auth] Mark News As Read'
);

export const PrivacyUpdate = createAction(
    '[Auth] Update privacy',
    props<{data: Privacy}>()
);

export const PrivacyUpdateTerminated = createAction(
    '[Auth] Privacy Update Success',
    props<{success: boolean}>()
);
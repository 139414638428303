import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { Subscription } from "rxjs";
import { logout } from "src/app/features/auth/auth.actions";
import {
  selectAuthState,
  selectCounterNotifications,
  selectLoggedUserHeaderInfo,
} from "src/app/features/auth/auth.selectors";
import { ToggleNotificationsMenu } from "src/app/features/notification/notification.actions";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public newsCounterNotification: number = 0;
  activeMenu: "user" | "alert" = null;
  public authState: any;
  public fullName = "";
  public company = "";
  public notifications = 0;
  public notificationsMenuOpened = false;
  public opened = false;

  public mobileMenuLinks = [
    { label: 'news.last', path: 'news/last' },
    { label: 'user.terms', path: 'user/terms' },
    { label: 'user.privacy', path: 'user/privacy' },
    { label: 'user.support', path: 'user/support' },
    { label: 'user.password', path: 'user/password' },
  ];

  constructor(
    private store: Store<{}>,
    public gaService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.select(selectAuthState).subscribe((state) => {
        this.authState = state;
      }),
      this.store
        .select(selectCounterNotifications)
        .subscribe((state) => (this.newsCounterNotification = state)),
      this.store.select(selectLoggedUserHeaderInfo).subscribe((state) => {
        this.fullName = state.fullName;
        this.company = state.company;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public openDropdown(type: "user" | "alert"): void {
    if (type === this.activeMenu) {
      this.activeMenu = null;
    } else {
      this.activeMenu = type;
    }
  }

  public logout(): void {
    this.gaService.event("menu_Logout");
    this.store.dispatch(logout());
  }

  public closeDropdown(): void {
    if (this.activeMenu === "alert" && this.notificationsMenuOpened) {
      this.store.dispatch(ToggleNotificationsMenu());
    }
    this.activeMenu = null;
  }
}

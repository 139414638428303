import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent {
  @Input() message: string;
}

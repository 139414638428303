import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import {forwardRef, LOCALE_ID, NgModule, Provider} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthGuard } from "./core/guards/auth-guard.service";
import { ApiInterceptor } from "./core/services/api-interceptor";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { WINDOW_PROVIDERS } from "./shared/services/window.service";
import { SharedModule } from "./shared/shared.module";
import { INIT, StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { AuctionEffects } from "./features/auction/auction.effects";
import { layoutReducer } from "./features/layout/layout.reducer";
import { auctionReducer } from "./features/auction/auction.reducer";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { environment } from "../environments/environment";
import { AuthEffects } from "./features/auth/auth.effects";
import {
  authReducer,
  DEFAULT_LANGUAGE_CODE,
} from "./features/auth/auth.reducer";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NewsEffects } from "./features/news/news.effects";
import { newsReducer } from "./features/news/news.reducer";
import localeIt from "@angular/common/locales/it";
import { registerLocaleData } from "@angular/common";
import { AuctionSelectedEffects } from "./features/auctionSelected/auction-selected.effects";
import { auctionSelectedReducer } from "./features/auctionSelected/auction-selected.reducer";
import { TabsLayoutComponent } from "./layouts/tabs-layout/tabs-layout.component";
import { routerReducer, StoreRouterConnectingModule } from "@ngrx/router-store";
import { favoritesReducer } from "./features/favorites/favorites.reducer";
import { vehicleModelsReducer } from "./features/vehicle-models/vehicle-models.reducer";
import { FavoritesEffects } from "./features/favorites/favorites.effects";
import { VehicleModelsEffects } from "./features/vehicle-models/vehicle-models.effects";
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";
import { TimerComponent } from "./layouts/timer/timer.component";
import { LayoutEffects } from "./features/layout/layout.effects";
import { UserContainerComponent } from "./modules/user/user-container/user-container.component";
import { UserEffects } from "./features/user/user.effects";
import { userReducer } from "./features/user/user.reducer";
import { ToastNotifyComponent } from "./layouts/toast-notify/toast-notify.component";
import { GalleryEffects } from "./features/vehicle-gallery/vehicle-gallery.effects";
import { galleryReducer } from "./features/vehicle-gallery/vehicle-gallery.reducer";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { notificationReducer } from "./features/notification/notification.reducer";
import { NotificationEffects } from "./features/notification/notification.effects";
import { logout } from "./features/auth/auth.actions";
import { groundingReducer } from "./features/grounding/grounding.reducer";
import { GroundingEffects } from "./features/grounding/grounding.effects";
import { GroundingSelectedEffects } from "./features/grounding-selected/grounding-selected.effects";
import { groundingSelectedReducer } from "./features/grounding-selected/grounding-selected.reducer";
import { groundingReturnedReducer } from "./features/grounding-returned/grounding-returned.reducer";
import { GroundingReturnedEffects } from "./features/grounding-returned/grounding-returned.effects";
import { GroundingReturnedSelectedEffects } from "./features/grounding-returned-selected/grounding-returned-selected.effects";
import { groundingReturnedSelectedReducer } from "./features/grounding-returned-selected/grounding-returned-selected.reducer";
import { NgPipesModule } from "ngx-pipes";
import { UserSupportComponent } from './modules/user/user-support/user-support.component';

registerLocaleData(localeIt);

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true,
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

function logoutMetaReducer(reducer: any) {
  return (state: any, action: any) => {
    if (action != null && action.type === logout.type) {
      return reducer(undefined, { type: INIT });
    }
    return reducer(state, action);
  };
}

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    TabsLayoutComponent,
    TimerComponent,
    UserContainerComponent,
    ToastNotifyComponent,
    UserSupportComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgPipesModule,
    RecaptchaV3Module,
    SharedModule,
    StoreModule.forRoot(
      {
        auction: auctionReducer,
        selectedAuction: auctionSelectedReducer,
        layout: layoutReducer,
        auth: authReducer,
        news: newsReducer,
        grounding: groundingReducer,
        groundingReturned: groundingReturnedReducer,
        selectedGrounding: groundingSelectedReducer,
        selectedGroundingReturned: groundingReturnedSelectedReducer,
        router: routerReducer,
        favorites: favoritesReducer,
        vehicleModels: vehicleModelsReducer,
        user: userReducer,
        gallery: galleryReducer,
        notification: notificationReducer,
      },
      {
        metaReducers: [logoutMetaReducer],
      }
    ),
    EffectsModule.forRoot([
      AuctionEffects,
      AuctionSelectedEffects,
      AuthEffects,
      NewsEffects,
      GroundingEffects,
      GroundingReturnedEffects,
      GroundingSelectedEffects,
      GroundingReturnedSelectedEffects,
      FavoritesEffects,
      VehicleModelsEffects,
      LayoutEffects,
      UserEffects,
      GalleryEffects,
      NotificationEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: false,
      logOnly: environment.production,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: DEFAULT_LANGUAGE_CODE,
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.gaTrackingCode),
    NgxGoogleAnalyticsRouterModule,
    StoreRouterConnectingModule.forRoot()
  ],
  providers: [
    AuthGuard,
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    WINDOW_PROVIDERS,
    { provide: LOCALE_ID, useValue: "it-IT" },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.captchaKey },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

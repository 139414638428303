import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map, switchMap, toArray } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { VehicleModelDto } from "../../models/veicle-model-dto";

@Injectable({
    providedIn:"root"
})
export class VehicleModelsService {
    
    constructor(
        private http: HttpClient
    ){
    }
    
    Gets(param?: {
        value?:number;
        text?: string;    
      }):Observable<VehicleModelDto[]>{

        var obsTypes: Observable<VehicleModelDto[]> = this.http.get<VehicleModelDto[]>(environment.apiUrl + 'buyer/models/autocomplete?q=' + param?.text);

        return obsTypes.pipe(
            switchMap((res: any[]) => res),
            filter(item => {
                var result = true;
                if(param){
                    Object.keys(param).forEach(key=>{
                        if(result && (<any>param)[key] && item[key]){
                            result = item[key].toLowerCase().indexOf((<any>param)[key].toLowerCase())>=0
                        }
                    })
                }
                return result;
            }),
            toArray(),
            map(res => res.sort((a, b) => a.text.localeCompare(b.text)))
        );
    }
}

import { Component } from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-user-support',
  templateUrl: './user-support.component.html',
  styles: [
  ]
})
export class UserSupportComponent {
  public apiUrl = environment.apiUrl;
  public supportDocs = [
    { label: 'contract', path: 'contractPath' },
    { label: 'useGuide', path: 'useGuidePath' },
    { label: 'userStatusManual', path: 'userStatusManualPath' },
  ];

  public infoDocs = [
    { label: 'dieselEa189', useApiUrl: true, path: 'dieselEa189Path' },
    { label: 'privacy', useApiUrl: false, path: 'privacyPath' },
    { label: 'cookies', useApiUrl: false, path: 'cookiesPath' }
  ];
}

import { GroundingDto } from "./grounding-dto";

export class VehicleDto {
  constructor(obj?: VehicleDto) {
    Object.assign(this, obj);
  }

  id: number;
  targa: string;
  descMarca: string;
  codModello: string;
  modello: string;
  descModello: string;
  dataImmat: string;
  km: number;
  kmTagliando: number;
  kw: number;
  cc: number;
  fuel: string;
  colore: string;
  fleetVehicle?: GroundingDto;
  maintenance?: any;
  optional: string;
  stoccaggio: string;
  vin: string;
  bodyType: string;
  seats: number;
  seats2: number;
  doors: number;
  gears: number;
  transmission: string;
  engineHp: number;
  coloreCarr: string;
  tipoColore: string;
  coloreInterni: string;
  tipoInterni: string;
  vehicleType: string;
  stoccaggioAddress: string;
  coverImage: string;
  carTaxExpiration: string;
  generalNote: string;
  hasZip: boolean;
  optionableGracePeriodEnd: string;
  returnAS400Code?: string;
  returnCity?: string;
}

export interface GalleryItem {
  id: number;
  url: string;
  copertina: boolean;
}

export interface GalleryWithVehicleId {
  vehicleId: number;
  images: GalleryItem[];
}

export enum VehicleDocType {
  PDF = "pdf",
  ZIP = "zip",
}

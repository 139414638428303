import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import {catchError, exhaustMap, mergeMap, switchMap, tap} from "rxjs/operators";
import * as GroundingSelectedActions from "./grounding-returned-selected.actions";
import { GroundingService } from "src/app/core/services/api-wrapped/grounding.service";
import { of } from "rxjs";
import { selectGroundingReturnedSelectedState } from "./grounding-returned-selected.selectors";
import { Store } from "@ngrx/store";
import { Location } from "@angular/common";
import { ToggleGroundingReturnedPreferenceListSuccess } from "../grounding-returned/grounding-returned.actions";
import {Router} from "@angular/router";

@Injectable()
export class GroundingReturnedSelectedEffects {
  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _location: Location,
    private _groundingService: GroundingService,
    private router: Router
  ) {}

  toggleGroundingReturnedPreference$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(GroundingSelectedActions.ToggleGroundingReturnedPreference),
      switchMap((action) =>
        this._groundingService.ToggleReturnedPreference(action.id).pipe(
          mergeMap((res) => [
            ToggleGroundingReturnedPreferenceListSuccess({
              id: action.id,
            }),
            GroundingSelectedActions.ToggleGroundingReturnedPreferenceSuccess(),
          ]),
          catchError((err) =>
            of(
              GroundingSelectedActions.ToggleGroundingReturnedPreferenceFailure()
            )
          )
        )
      )
    );
  });

  acceptPurposeGroundingReturned$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(GroundingSelectedActions.AcceptProposalGroundingReturned),
      exhaustMap((action) =>
        this._groundingService.AcceptProposal(action.id)
          .pipe(
            tap((response) => {
              this.router.navigate(['auction', 'detail', response.auction_id]);
            }),
            mergeMap((res) => [
              GroundingSelectedActions.AcceptProposalGroundingReturnedSuccess(),
            ]),
            catchError((err) =>
              of(
                GroundingSelectedActions.AcceptProposalGroundingReturnedFailure()
              )
            )
          )
        )
      );
    }
  );

  rejectPurposeGroundingReturned$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(GroundingSelectedActions.RejectProposalGroundingReturned),
      switchMap((action) =>
        this._groundingService.RejectProposal(action.id).pipe(
          mergeMap((res) => [
            GroundingSelectedActions.RejectProposalGroundingReturnedSuccess(),
          ]),
          catchError((err) =>
            of(
              GroundingSelectedActions.RejectProposalGroundingReturnedFailure()
            )
          )
        )
      )
    );
  });

  loadGroundingReturnedById$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroundingSelectedActions.LoadGroundingReturnedById),
      concatLatestFrom((action) =>
        this._store.select(selectGroundingReturnedSelectedState)
      ),
      switchMap(([action, store]) =>
        this._groundingService.GetReturnedById(action.id).pipe(
          mergeMap((res) => [
            GroundingSelectedActions.LoadGroundingReturnedByIdSuccess({
              record: res,
            }),
          ]),
          catchError((err) => {
            this._location.back();
            return of(
              GroundingSelectedActions.LoadGroundingReturnedByIdFailure()
            );
          })
        )
      )
    )
  );
}

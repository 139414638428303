import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import * as GroundingActions from "./grounding.actions";
import { GroundingService } from "src/app/core/services/api-wrapped/grounding.service";
import { Store } from "@ngrx/store";
import { selectGroundingState } from "./grounding.selectors";
import { forkJoin, of } from "rxjs";

@Injectable()
export class GroundingEffects {
  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _groundingService: GroundingService
  ) {}

  loadGrounding$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroundingActions.LoadGrounding),
      concatLatestFrom((action) => this._store.select(selectGroundingState)),
      switchMap(([action, store]) =>
        forkJoin([
          this._groundingService.Gets({
            page: store.page,
            filters: store.filters,
          }),
        ]).pipe(
          switchMap((res) => {
            let canLoadMoreRecords: boolean = false;

            if (Array.isArray(res[0])) {
              canLoadMoreRecords = !!res[0].length;
            } else {
              res[0] = [res[0]];
            }

            return [
              GroundingActions.LoadGroundingSuccess({
                records: store.list.concat(res[0]),
                canLoadMoreRecords,
              }),
            ];
          }),
          catchError((err) => of(GroundingActions.LoadGroundingFailure()))
        )
      )
    )
  );

  applyGroundingFilters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroundingActions.ApplyGroundingFilters),
      concatLatestFrom((action) => this._store.select(selectGroundingState)),
      map(([action, store]) => GroundingActions.LoadGrounding())
    )
  );

  setGroundingNextPage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroundingActions.SetGroundingNextPage),
      concatLatestFrom((action) => this._store.select(selectGroundingState)),
      map(([action, store]) => GroundingActions.LoadGrounding())
    )
  );

  resetGroundingFilters$ = createEffect(() =>
    this._actions$.pipe(
      ofType(GroundingActions.ResetGroundingFilters),
      concatLatestFrom((action) => this._store.select(selectGroundingState)),
      map(([action, store]) => GroundingActions.LoadGrounding())
    )
  );
}

import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';

export const userFeatureKey = 'user';
export const DEFAULT_LANGUAGE_CODE = 'it';

export interface State {
  isFetching: boolean;
  error: any;
  success: boolean;
}

export const initialState: State = {
  isFetching: false,
  error: null,
  success: false
};


export const userReducer = createReducer(
  initialState,

  on(UserActions.ClearFormErrors, state => initialState),

  // Loading
  on(UserActions.SaveUserCompany,
    UserActions.SaveUserPassword,
    UserActions.SaveUserProfile,
    UserActions.AddUserAccount, (state, action) => ({
    ...state,
    ...{
      error: null,
      success: false,
      isFetching: true
    }
  })),

  // Failure
  on(UserActions.SaveUserCompanyFailure,
    UserActions.SaveUserPasswordFailure,
    UserActions.SaveUserProfileFailure,
    UserActions.AddUserAccountFailure, (state, action) => ({
    ...state,
    ...{
      error: action.error,
      success: false,
      isFetching: false
    }
  })),

  // Success
  on(UserActions.SaveUserCompanySuccess,
    UserActions.SaveUserPasswordSuccess,
    UserActions.SaveUserProfileSuccess,
    UserActions.AddUserAccountSuccess, (state, action) => ({
    ...state,
    ...{
      error: null,
      success: true,
      isFetching: false
    }
  })),
);


import {HttpClient, HttpParams} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuctionDto, AuctionPricePolling, DistributionVehiclesDto } from "../../models/auction-dto";
import { AuctionFilterDto, AuctionListRequestDto } from "../../models/auction-filter-dto";
import { AuctionOfferDto } from "../../models/auction-offer-dto";
import { GalleryItem, VehicleDocType, VehicleDto } from "../../models/vehicle-dto";
import {HEADER_TOTAL_COUNT} from "../../../shared/constants";

@Injectable({
    providedIn:"root"
})
export class AuctionService {

    constructor(
        private http: HttpClient
    ){
    }

    GetById(id: number): Observable<AuctionDto>{
        return this.http.get<AuctionDto>(environment.apiUrl + 'buyer/auctions/' + id);
    }

    TogglePreference(id: number): Observable<{}> {
        return this.http.post<AuctionDto>(environment.apiUrl + 'buyer/auctions/'+ id +'/favourite/toggle', {});
    }

    HandleOffer(params: AuctionOfferDto): Observable<AuctionDto> {
        return this.http.post<AuctionDto>(environment.apiUrl + 'buyer/auction/offer/' + params.auctionId, params);
    }

    BuyNow(params: AuctionOfferDto): Observable<{}> {
        return this.http.post<AuctionDto>(environment.apiUrl + 'buyer/auction/offer/' + params.auctionId, params);
    }

    DistributeValues(auctionId: number, vehicleOffer: DistributionVehiclesDto[]): Observable<{}> {
        return this.http.post<AuctionDto>(environment.apiUrl + 'buyer/auction/split/' + auctionId, {vehicleOffer});
    }

    GetCounterAuctionsToPay(): Observable<number> {
        return this.http.get<number>(environment.apiUrl + 'buyer/auction/topay');
    }

    Gets(endedAuctions: boolean, requestParams: AuctionListRequestDto): Observable<{ data: AuctionDto[]; totalCount: number }>{
        let url = environment.apiUrl + "buyer/auctions";
        if(endedAuctions) {
            url += "/ended";
        }
        let params = new HttpParams();

        if(endedAuctions) {
            params = params
                        .set('order', requestParams.sortOrder)
                        .set('page', requestParams.page.toString());

            params = this.buildHttpParams(params, requestParams.filters);
        }
        return this.http.get<AuctionDto[]>(url, { params, observe: 'response' }).pipe(
          map(response => {
              return {
                  data: response.body,
                  totalCount: +response.headers.get(HEADER_TOTAL_COUNT)
              }
          })
        );
    }

    DownloadFile(requestParams: AuctionListRequestDto) {
        let params = new HttpParams();
        params = this.buildHttpParams(params, requestParams.filters);

        return this.http.get(
            environment.apiUrl + 'buyer/auctions/xlsended',
            {observe: 'response', responseType: 'blob', params}
        );
    }

    DownloadVehicleDoc(vehicle: VehicleDto, type: VehicleDocType) {
        return this.http.get(
            environment.apiUrl + 'buyer/auction/download_vehicle/'+ vehicle.id + '/' + type,
            {observe: 'response', responseType: 'blob'}
        );
    }

    DownloadAuctionDoc(docId: number) {
        return this.http.get(
            environment.apiUrl + 'buyer/auction/download_file/'+ docId,
            {observe: 'response', responseType: 'blob'}
        );
    }

    GetVehicleGallery(vehicleId: number, auctionId: number): Observable<GalleryItem[]> {
        return this.http.get<GalleryItem[]>(environment.apiUrl + 'buyer/auction/'+ auctionId +'/vehicle/'+ vehicleId +'/gallery').pipe(
            map(images => images.sort((first, second) => {
                    if(first.copertina) {
                        return -1;
                    } else if(second.copertina) {
                        return 1;
                    } else return 0;
                })
            )
        );
    }

    GetAuctionPriceList(auctionIds: number[]): Observable<AuctionPricePolling[]> {
        let params = new HttpParams();
        for (let id of auctionIds) {
            params = params.append('ids[]', id.toString());
        }

        return this.http.get<AuctionPricePolling[]>(environment.apiUrl + 'buyer/auction/list_price', {params});
    }

    buildHttpParams(httpParams: HttpParams, filters: AuctionFilterDto) {

        if(filters.buyItNow) {
            httpParams = httpParams.set('filters[buyNow]', filters.buyItNow ? 'true' : 'false');
        }
        if(filters.status && filters.status !== -1) {
            const statusToken = filters.status.toString().split('&');
            statusToken.forEach(value => {
                httpParams = httpParams.append('filters[idstatoasta][]', value);
            });
        }

        if(filters.type && filters.type !== -1) {
            httpParams = httpParams.set('filters[auctionType]', filters.type.toString());
        }

        if(filters.carSingleMultiple){
            httpParams = httpParams.set('filters[package]', filters.carSingleMultiple);
        }

        if(filters.validity){
            const from = filters.validity.from.getFullYear() + '/' + (filters.validity.from.getMonth() + 1) + '/'+ filters.validity.from.getDate();
            const to = filters.validity.to.getFullYear() + '/' + (filters.validity.to.getMonth() + 1) + '/'+ filters.validity.to.getDate();
            httpParams = httpParams.set('filters[dateFrom]', from);
            httpParams = httpParams.set('filters[dateTo]', to);
        }

        if(filters.onlyWin) {
            httpParams = httpParams.set('filters[onlyWin]', filters.onlyWin ? 'true' : 'false');
        }

        if(filters.text) {
            httpParams = httpParams.set('filters[id]', filters.text);
        }

        if(filters.plate) {
            httpParams = httpParams.set('filters[plate]', filters.plate);
        }

        if(filters.makeCode) {
            httpParams = httpParams.set('filters[brand][]', filters.makeCode);
        }

        return httpParams;
    }

    GenerateZip(vehicleId: number): Observable<any> {
        return this.http.post<any>(environment.apiUrl + 'buyer/auction/generate_zip/' + vehicleId, {});
    }
}

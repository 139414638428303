import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLayout from './layout.reducer';

export const selectLayoutState = createFeatureSelector<fromLayout.State>(
  'layout'
);

export const selectFastBidStep = createSelector(
  selectLayoutState, state => state.staticValues.fastBidStep
)

export const selectCurrentTimestamp = createSelector(
  selectLayoutState, state => state.timestamp
)
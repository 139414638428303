import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { VehicleModelDto } from "../../models/veicle-model-dto";

@Injectable({
    providedIn:"root"
})
export class FavoritesService {
    
    constructor(
        private http: HttpClient
    ){
    }

    Gets():Observable<VehicleModelDto[]>{   
        return this.http.get<VehicleModelDto[]>(environment.apiUrl + 'buyer/models');
    }

    AddFavorite(id: number): Observable<VehicleModelDto> {
        return this.http.post<VehicleModelDto>(environment.apiUrl + 'buyer/models', {
            id
        });
    }

    RemoveFavorite(id: number): Observable<VehicleModelDto> {
        return this.http.delete<VehicleModelDto>(environment.apiUrl + 'buyer/models/' + id);
    }
}

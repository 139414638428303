<div [class]="'c-jump-nav-bar c-horizontal-slider u-hide@s ' + route.snapshot.routeConfig.path" [ngClass]="{'opened': opened}">
    <button *ngIf="opened" (click)="opened = false" class="c-btn c-btn--icon-only close"><i class="c-btn__icon c-icon c-icon--[semantic-close]" aria-hidden="true" role="img"></i></button>
    <div class="o-page-wrap">
        <nav class="c-jump-nav-container">
            <div class="c-jump-nav-wrapper">
                <button (click)="opened = true" class="c-btn c-btn--icon-only c-btn--secondary hamburger"><i class="c-btn__icon c-icon c-icon--[semantic-menu]" aria-hidden="true" role="img"></i></button>
                <ul class="c-jump-nav">
                    <li class="js-jump-nav__item d-flex" *ngFor="let item of childrenRoutes">
                        <a (click)="opened = false" [routerLink]="[item.path]" class="js-jump-nav__link" routerLinkActive="is-active">
                            {{"menu." + route.snapshot.routeConfig.path + "." + item.path| translate}}
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>

<section class="o-content-section  ">
    <div class="o-page-wrap">
        <router-outlet></router-outlet>
    </div>
</section>

import { createReducer, on } from "@ngrx/store";
import { GroundingDto } from "src/app/core/models/grounding-dto";
import { VehicleDto } from "src/app/core/models/vehicle-dto";
import * as GroundingReturnedSelectedActions from "./grounding-returned-selected.actions";

export interface State {
  details: VehicleDto;
  isFetching: boolean;
  fetched: boolean;
}

export const initialState: State = {
  details: null,
  fetched: false,
  isFetching: false,
};

export const groundingReturnedSelectedReducer = createReducer(
  initialState,

  on(
    GroundingReturnedSelectedActions.LoadGroundingReturnedById,
    (state, action) => ({
      ...initialState,
      isFetching: true,
    })
  ),
  on(
    GroundingReturnedSelectedActions.LoadGroundingReturnedByIdSuccess,
    (state, action) => ({
      ...state,
      details: action.record,
      isFetching: false,
    })
  ),
  on(
    GroundingReturnedSelectedActions.LoadGroundingReturnedByIdFailure,
    (state) => ({
      ...state,
      isFetching: false,
    })
  ),
  on(
    GroundingReturnedSelectedActions.ToggleGroundingReturnedPreference,
    (state, action) => {
      return state;
    }
  ),
  on(
    GroundingReturnedSelectedActions.AcceptProposalGroundingReturned,
(state) => ({
          ...state,
          isFetching: true,
      })
  ),
  on(
    GroundingReturnedSelectedActions.RejectProposalGroundingReturned,
    (state, action) => {
      return state;
    }
  ),
  on(
    GroundingReturnedSelectedActions.AcceptProposalGroundingReturnedSuccess,
    (state, action) => {
      if (!state.details) {
        return state;
      }

      let vehicle = new GroundingDto(state.details.fleetVehicle);
      let option = vehicle.getFirstOption;
      if (option) {
        vehicle.firstOptions = [
          { ...option, acceptedAt: new Date(), status: 5 },
        ];
      } else {
        return state;
      }

      return {
        ...state,
        details: {
          ...state.details,
          fleetVehicle: vehicle,
        },
      };
    }
  ),
  on(
    GroundingReturnedSelectedActions.RejectProposalGroundingReturnedSuccess,
    (state, action) => {
      if (!state.details) {
        return state;
      }

      let vehicle = new GroundingDto(state.details.fleetVehicle);
      let option = vehicle.getFirstOption;
      if (option) {
        vehicle.firstOptions = [{ ...option, status: 6 }];
      } else {
        return state;
      }

      return {
        ...state,
        details: {
          ...state.details,
          fleetVehicle: vehicle,
        },
      };
    }
  ),
  on(
    GroundingReturnedSelectedActions.ToggleGroundingReturnedPreferenceSuccess,
    (state, action) => {
      if (!state.details) {
        return state;
      }

      let vehicle = new GroundingDto(state.details.fleetVehicle);
      let option = vehicle.getFirstOption;
      if (option) {
        option = { ...option, status: option.status === 2 ? 8 : 2 };
      } else {
        option = { status: 2 };
      }

      vehicle.firstOptions = [option];

      return {
        ...state,
        details: {
          ...state.details,
          fleetVehicle: vehicle,
        },
      };
    }
  )
);

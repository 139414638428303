import { createAction, props } from '@ngrx/store';
import { AddUserAccountDto, AgencyFormDto, BuyerData, ChangePasswordDto, SaveProfileDto } from 'src/app/core/models/user-dto';

export const SaveUserCompany = createAction(
  '[User] Save User Company',
  props<{data: BuyerData}>()
);
export const SaveUserCompanySuccess = createAction(
  '[User] Save User Company Success'
);
export const SaveUserCompanyFailure = createAction(
  '[User] Save User Company Failure',
  props<{ error: any }>()
);

export const SaveUserPassword = createAction(
  '[User] Save User Password',
  props<{data: ChangePasswordDto}>()
);
export const SaveUserPasswordSuccess = createAction(
  '[User] Save User Password Success'
);
export const SaveUserPasswordFailure = createAction(
  '[User] Save User Password Failure',
  props<{ error: any }>()
);

export const SaveUserProfile = createAction(
  '[User] Save User Profile',
  props<{data: SaveProfileDto}>()
);
export const SaveUserProfileSuccess = createAction(
  '[User] Save User Profile Success'
);
export const SaveUserProfileFailure = createAction(
  '[User] Save User Profile Failure',
  props<{ error: any }>()
);

export const AddUserAccount = createAction(
  '[User] Add User Account',
  props<{data: AddUserAccountDto}>()
);
export const AddUserAccountSuccess = createAction(
  '[User] Add User Account Success'
);
export const AddUserAccountFailure = createAction(
  '[User] Add User Account Failure',
  props<{ error: any }>()
);

export const DownloadAgencyFormPdf = createAction(
  '[User] Download Agency Form Pdf',
  props<{data: AgencyFormDto}>()
);
export const DownloadAgencyFormPdfSuccess = createAction(
  '[User] Download Agency Form Pdf Success'
);
export const DownloadAgencyFormPdfFailure = createAction(
  '[User] Download Agency Form Pdf Failure',
  props<{ error: any }>()
);

export const ClearFormErrors = createAction(
  '[User] Clear Form Errors'
);
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { News } from "../../models/news-dto";

@Injectable({
    providedIn:"root"
})
export class NewsService {
    
    constructor(
        private http: HttpClient
    ){
    }

    Gets():Observable<News[]>{   
        return this.http.get<News[]>(environment.apiUrl + 'buyer/news');
    }

    DownloadFile(newsId: number, fileId: number) {   
        return this.http.get(
            environment.apiUrl + 'buyer/news/' + newsId + '/file/' + fileId +'/download', 
            {observe: 'response', responseType: 'blob'}
        );
    }
}
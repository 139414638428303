<div class="o-fieldset__row">
  <div class="o-layout">
    <div *ngFor="let field of fields" class="o-layout__item  u-1/2  u-1/1@xs">
        <div class="c-form-field  ">
            <label class="c-form-field__label">
              <ngx-skeleton-loader [theme]="{
                'border-radius': '0',
                width: '30%',
                'margin-bottom': '0',
                'background-color': 'rgb(0, 99, 132, 0.3)',
                height: '15px'
              }"></ngx-skeleton-loader>
            </label>
            <div class="c-form-field__box">
                <div class="c-input  ">
                  <ngx-skeleton-loader style="width: 100%;" [theme]="{
                    'border-radius': '0',
                    height: '44px'
                  }"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div> 
  </div>
</div>                     

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription, timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GetServerTimestamp } from 'src/app/features/layout/layout.actions';

@Component({
  selector: 'app-timer',
  template: `
    <router-outlet></router-outlet>
  `,
  styles: [
  ]
})
export class TimerComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<{}>,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      timer(0, 60000).subscribe(_ => {
        this.store.dispatch(GetServerTimestamp());
      })
    );
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(params => this.store.dispatch(GetServerTimestamp()));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}

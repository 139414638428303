import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as Fuse from 'fuse.js';
import { AuctionDto } from 'src/app/core/models/auction-dto';
import { AuctionFilterDto } from 'src/app/core/models/auction-filter-dto';
import * as fromAuction from './auction.reducer';

const PAGE_SIZE = 25;

export const selectAuctionState = createFeatureSelector<fromAuction.State>(
  'auction'
);

export const selectAuctionFilterMakesState = createSelector(
  selectAuctionState, state => state.filersMake
);

const options = {
   isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  minMatchCharLength: 4,
  location: 0,
  threshold: 0.1,
  distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  keys: [
    "id",
    "vehicles.targa",
    "vehicles.descMarca",
    "vehicles.modello",
    "vehicles.descModello",
    "vehicles.colore"
  ]
};


export const selectAuctionsPaginated =
createSelector(
  selectAuctionState, 
  (auctionState) => {  
    // Aste finite vengono filtrate e ordinate server side
    if(auctionState.statusDesc === 'finished') {
      return {
        totalItems: auctionState.auctionsFinished.length + (auctionState.canLoadMoreRecords ? 1 : 0), // Se l api non da piu records togliamo il pulsante carica più aste
        items: auctionState.auctionsFinished?.map(auc => new AuctionDto(auc)),
        statusDesc: auctionState.statusDesc
      };
    }

    let filteredList: AuctionDto[] = auctionState && auctionState.auctions.map(auction => new AuctionDto(auction)) || [];
     
    if(auctionState.filters.text){
      const fuse = new Fuse(auctionState.auctions, options);
      filteredList = fuse.search(auctionState.filters.text);
    }
  
    filteredList = filteredList.
      filter((auction) => {
          auction = new AuctionDto(auction);
          
          let result = true;
          let filters : AuctionFilterDto = auctionState.filters;

          // Accettabili solo pending e future (casistica in cui asta finisce dopo averla visualizzata e altrimenti rimarrebbe in lista)
          // Polling price list cambia idstatoasta ----> se finita non la vedo piu
          if(!auction.isPending && !auction.mustStart) {
            return false;
          }
          // Nel caso visualizzo le aste preferite
          if(auctionState.statusDesc === 'favourite' && !auction.isFavourite) {
            return false;
          }

          if(result && filters.status && filters.status !== -1) {
            result = auction.idstatoasta == filters.status;
          }

          if(result && filters.buyItNow) {
            result = auction.buyNowFlag;       
          }

          if(result && filters.type && filters.type !== -1) {
            result = auction.type === filters.type;  
          }

          if(result &&  filters.km ){
            auction.vehicles.forEach(v => {
              if(result && v.km < filters.km[0]) result = false;
              if(result && v.km > filters.km[1]) result = false;
            });
          }

          if(result &&  filters.registrationYear ){
            auction.vehicles.forEach(v => {
              if(v.dataImmat){
                  var y: number = +v.dataImmat.slice(-4);
                  if(result && y < filters.registrationYear[0]) result = false;
                  if(result && y > filters.registrationYear[1]) result = false;
              } else {
                result = false;
              }
            });
          }

          if(result &&  filters.validity ){
            let mStart = new Date( auction.astaInizioDataora);
            let mEnd = new Date( auction.astaFineDataora);

            const diffStart = filters.validity.from.getDate() - mStart.getDate();
            const diffEnd = filters.validity.to.getDate() - mEnd.getDate();

            if(diffStart>0) result = false;
            if(diffEnd<0) result = false;            
          }


          if(result && filters.carSingleMultiple){
            let singleMultiple =   auction.vehicles.length === 1  ? "S" : "M";
            result =  filters.carSingleMultiple === singleMultiple ;            
          }
          
          if(result && filters.powerSupply && filters.powerSupply.length>0){
            let founds = [];
            auction.vehicles.forEach(x=>{
                let element = filters.powerSupply.find(r=> r === x.fuel);
                if(element) founds.push(element);
              })
            result = founds.length>0;
          }
          return result;
      });
    
    filteredList = filteredList.sort((a:AuctionDto,b:AuctionDto)=>{
      
      let up = a.astaFineDataora;
      let down = b.astaFineDataora;
    
      if(a.mustStart) {
        return b.mustStart ? 0 : 1;
      }

      if(b.mustStart) {
        return a.mustStart ? 0 : -1;
      }

      if(auctionState.sortOrder === 'desc') {
        if (up > down) {
          return -1;
        }
        return 1;        
      }

      if(down > up) {
        return -1;
      }
      return 1;
    });
      
    return {
      totalItems: filteredList.length,
      items: filteredList.slice(0, auctionState.page ? auctionState.page * PAGE_SIZE : PAGE_SIZE ),
      statusDesc: auctionState.statusDesc
    };
  }
);


export const selectAuctionsCounters = createSelector(selectAuctionState, 
  (auctionState) => ({
    pending: auctionState.auctions.length,
    finished: auctionState.toPay ?? 0,
    favourite: auctionState.auctions.filter(auction => auction.isFavourite).length
  })
)

export const selectAuctionsSort = createSelector(selectAuctionState, 
  (auctionState) => ({
    sortOrder: auctionState.sortOrder,
    sortBy: auctionState.sortBy
  })
);

export const selectIsFetchingAuctions = createSelector(selectAuctionState, auctionState => auctionState.isFetching);

export const selectActiveFiltersCount = createSelector(selectAuctionState, auctionState => {
  return Object.keys(auctionState.filters).filter(key => {
    const filters: {[key: string]: boolean} = {
      'text': false,
      'makeCode': auctionState?.filters['makeCode'] !== '',
      'plate': auctionState?.filters['plate'] !== '',
      'buyItNow': !!auctionState?.filters['buyItNow'],
      'onlyWin': !!auctionState?.filters['onlyWin'],
      'status': auctionState?.filters['status'] !== -1,
      'type': auctionState?.filters['type'] !== -1,
      'powerSupply': auctionState?.filters['powerSupply']?.length !== 0,
      'km': auctionState?.filters['km']?.length === 2 && (auctionState?.filters['km'][0] !== fromAuction.KM_FILTER[0] || auctionState?.filters['km'][1] !== fromAuction.KM_FILTER[1]),
      'registrationYear': auctionState?.filters['registrationYear']?.length === 2 && (auctionState?.filters['registrationYear'][0] !== fromAuction.YEAR_FILTER[0] || auctionState?.filters['registrationYear'][1] !== fromAuction.YEAR_FILTER[1])
    }

    if (key in filters) {
      return filters[key];
    }

    return true;
  }).length;
});

export const selectSearchFilter = createSelector(selectAuctionState, auctionState => auctionState.filters?.text);

export const selectAuctionFilters = createSelector(selectAuctionState, 
  auctionState => auctionState.filters
);

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuctionDto } from 'src/app/core/models/auction-dto';
import * as fromAuctionSelected from './auction-selected.reducer';

export const selectAuctionSelectedState = createFeatureSelector<fromAuctionSelected.State>(
  'selectedAuction'
);

export const selectAuctionSelected = createSelector(selectAuctionSelectedState, auctionState => {
  return auctionState.auctionDetail ? new AuctionDto(auctionState.auctionDetail) : null;
});

import { Routes } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    {
        path: 'pages',
        loadChildren: () => import('../pages/content-pages/content-pages.module').then(m => m.ContentPagesModule)
    },
    {
        path: 'login',
        loadChildren: () => import('../pages/sign-in/sign-in.component').then(m => m.SignInModule)
    },
    {
        path: 'signup',
        loadChildren: () => import('../pages/sign-up/sign-up.component').then(m => m.SignUpModule)
    },
    {
        path: 'reset-password',
        loadChildren: () => import('../pages/reset-password/reset-password.component').then(m => m.ResetPasswordModule)
    }
];

import { createAction, props } from '@ngrx/store';
import { AuctionDto, AuctionPricePolling, DistributionVehiclesDto } from 'src/app/core/models/auction-dto';
import { SortOption } from 'src/app/core/models/sort-option-dto';
import {MakeDto} from "../../core/models/make-dto";

export const LoadAuctions = createAction(
  '[Auction] Load Auctions',
  props<{status: string}>()
);
export const LoadAuctionsSuccess = createAction(
  '[Auction] Load Auctions Success',
  props<{records: AuctionDto[], canLoadMoreRecords: boolean}>()
);
export const LoadAuctionsFailure = createAction(
  '[Auction] Load Auctions Failure'
);
export const SetAuctionsNextPage = createAction(
  '[Auction] Auctions Next Page'
);
export const SetDraftFilter = createAction(
  '[Auction] Set Draft Filter',
  props<{filter: {[key: string]: any}}>()
);
export const ApplyAuctionsFilters = createAction(
  '[Auction] Apply Filters'
);
export const ResetAuctionsFilters = createAction(
  '[Auction] Reset Filters'
);
export const SetAuctionsSort = createAction(
  '[Auction] Set Sort',
  props<{sort: SortOption}>()
);
export const MakeOfferSuccessList = createAction(
  '[Auction] Make Offer Success List',
  props<{auction: AuctionDto}>()
);
export const DeleteOfferSuccessList = createAction(
  '[Auction] Delete Offer Success List',
  props<{auction: AuctionDto}>()
);
export const BuyNowSuccessList = createAction(
  '[Auction] Buy Now Success List',
  props<{auction: AuctionDto}>()
);
export const TogglePreferenceListSuccess = createAction(
  '[Auction] Toggle Preference List Success',
  props<{auctionId: number}>()
);
export const DistributeValuesListSuccess = createAction(
  '[Auction] Distribute Values List Success',
  props<{auctionId: number, distribution: DistributionVehiclesDto[]}>()
);
export const SetCounterToPay = createAction(
  '[Auction] Set Counter To Pay',
  props<{count: number}>()
);
export const GetCounterAuctionsToPay = createAction(
  '[Auction] Get Counter Auctions To Pay'
);
export const GetCounterAuctionsToPaySuccess = createAction(
  '[Auction] Get Counter Auctions To Pay Success',
  props<{counter: number}>()
);
export const GetCounterAuctionsToPayFailure = createAction(
  '[Auction] Get Counter Auctions To Pay Failure'
);
export const GetAuctionPriceList = createAction(
  '[Auction] Get Auction Price List'
);
export const GetAuctionPriceListSuccess = createAction(
  '[Auction] Get Auction Price List Success',
  props<{priceList: AuctionPricePolling[]}>()
);
export const GetAuctionPriceListFailure = createAction(
  '[Auction] Get Auction Price List Failure'
);
export const DownloadExcel = createAction(
  '[Auction] Download Excel'
);
export const LoadMakesFilter = createAction(
  '[Auction] Load makes filter'
);
export const LoadMakesFilterSuccess = createAction(
  '[Auction] Load makes filter success',
  props<{makes: MakeDto[]}>()
);
export const LoadMakesFilterFailure = createAction(
  '[Auction] Load makes filter failure'
);

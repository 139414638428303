import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State } from './favorites.reducer';
import * as FavoritesActions from './favorites.actions';
import { FavoritesService } from 'src/app/core/services/api-wrapped/favorites.service';
import { selectFavoritesState } from './favorites.selectors';
import { of } from 'rxjs';

@Injectable()
export class FavoritesEffects {
  constructor (
    private actions$: Actions,
    private store: Store<State>,
    private favoritesService: FavoritesService
  ) {}

  loadFavorites$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FavoritesActions.LoadFavorites),
      concatLatestFrom(action => this.store.select(selectFavoritesState)),
      concatMap(([action, store]) => store.favorites.length > 0 && !action.refresh ? of(store.favorites) : this.favoritesService.Gets()),
      map(records => {
        return records.length > 0 ? FavoritesActions.LoadFavoritesSuccess({records}) : FavoritesActions.LoadFavoritesFailure();
      })
    )
  });

  addFavorite$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(FavoritesActions.AddFavorite),
      concatLatestFrom(action => this.store.select(selectFavoritesState)),
      concatMap(([action, store]) => this.favoritesService.AddFavorite(
        action.id
      ).pipe(
        map(res => ({payload: action.id, res})),
      )),
      map(record => {
        return record.res ? FavoritesActions.LoadFavorites({
          refresh: true
        })/*  FavoritesActions.AddFavoriteSuccess()*/ : FavoritesActions.AddFavoriteFailure();
      })
    )
  });

  removeFavorite$ = createEffect(() => {

    return this.actions$.pipe(
      ofType(FavoritesActions.RemoveFavorite),
      concatLatestFrom(action => this.store.select(selectFavoritesState)),
      concatMap(([action, store]) => this.favoritesService.RemoveFavorite(
        action.id
      ).pipe(
        map(res => ({payload: action.id, res})),
        map(record => {
          return record.res ? FavoritesActions.RemoveFavoriteSuccess({id: action.id}) : FavoritesActions.RemoveFavoriteFailure();
        })
      ))
    )
  });

}

<div *ngIf="message" class="o-fieldset__row">
  <div class="o-layout">
      <div class="o-layout__item  u-1/1  u-1/1@xs u-mb">
          <div class="c-notification c-notification--warning u-mb-none u-pv-small">
              <div class="c-notification__outer-wrap">
                  <div class="c-notification__inner-wrap u-p-none">
                      <i class="c-notification__status-icon d-flex flex-column justify-content-center" aria-hidden="true" role="img"></i>
                      <div class="c-notification__content">
                          <h3 class="c-notification__title u-mb-xxsmall">{{"attention" | translate}}</h3>
                          <p class="c-notification__text">{{message}}</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
import { createAction, props } from '@ngrx/store';
import { StaticValues } from 'src/app/core/models/static-values-dto';

export const ToggleFiltersMenu = createAction(
  '[Layout] Toggle Filters Menu'
);

export const CloseFiltersMenu = createAction(
  '[Layout] Close Filters Menu'
);

export const GetServerTimestamp = createAction(
  '[Layout] Get Server Timestamp'
);
export const GetServerTimestampSuccess = createAction(
  '[Layout] Get Server Timestamp Success',
  props<{timestamp: number}>()
);
export const GetServerTimestampFailure = createAction(
  '[Layout] Get Server Timestamp Failure'
);

export const GetStaticValues = createAction(
  '[Layout] Static Values Request'
);

export const GetStaticValuesSuccess = createAction(
  '[Layout] Static Values Success',
  props<{ data: StaticValues}>()
);

export const GetStaticValuesFailure = createAction(
  '[Layout] Static Values Failure',
  props<{ error: any }>()
);

export const ShowNotifyMessage = createAction(
  '[Layout] Show Notify Message',
  props<{message: string | {errors?: string[]}; level?: 'success' | 'error'}>()
);

export const HideNotifyMessage = createAction(
  '[Layout] Hide Notify Message'
);

export const CloseBanner = createAction(
  '[Layout] Close Banner',
  props<{bannerType: 'auctionList' | 'auctionDetail'}>()
);
import { createAction, props } from "@ngrx/store";
import { GalleryWithVehicleId } from "src/app/core/models/vehicle-dto";

export const OpenVehicleGallery = createAction(
  '[Vehicle Gallery] Open Vehicle Gallery',
  props<{title: string, vehicleId: number, auctionId: number}>()
);
export const OpenVehicleGallerySuccess = createAction(
  '[Vehicle Gallery] Open Vehicle Gallery Success',
  props<{title: string, gallery: GalleryWithVehicleId}>()
);
export const OpenVehicleGalleryFailure = createAction(
  '[Vehicle Gallery] Open Vehicle Gallery Failure',
  props<{error: string}>()
);
export const CloseVehicleGallery = createAction(
  '[Vehicle Gallery] Close Vehicle Gallery'
);
import { groupByKey } from "../functions/common-functions";
import { VehicleDto } from "./vehicle-dto";

export const STATUS_IN_CREAZIONE = 1;
export const STATUS_PRONTA = 2;
export const STATUS_IN_CORSO = 3;
export const STATUS_CONCLUSA = 4;
export const STATUS_AGGIUDICATA = 5;
export const STATUS_POST_VENDITA = 6;
export const STATUS_PAGATA = 7;
export const STATUS_NON_AGGIUDICATA = 8;
export const STATUS_ELIMINATA = 9;
export const STATUS_COMPLETATA = 10;
export const STATUS_FATTURATA = 11;

export const FINISHED_STATUS = [ 
    STATUS_CONCLUSA,  
    STATUS_AGGIUDICATA,
    STATUS_POST_VENDITA,
    STATUS_PAGATA,
    STATUS_NON_AGGIUDICATA,
    STATUS_COMPLETATA,
    STATUS_FATTURATA
];
export const FINISHED_STATUS_FILTERS = [
    STATUS_CONCLUSA + '&' + STATUS_AGGIUDICATA,
    STATUS_POST_VENDITA + '&' + STATUS_FATTURATA,
    STATUS_PAGATA + '&' + STATUS_COMPLETATA, // pagata e fatturata stessa tendina
    STATUS_NON_AGGIUDICATA
];
export const PENDING_STATUS = [STATUS_IN_CORSO,STATUS_PRONTA];



export class AuctionDto{
    constructor(obj?: AuctionDto) {
        Object.assign(this, obj);
    }

    id: number;
    descrizione: string;
    astaInizioDataora: string;
    astaFineDataora: string;
    idstatoasta: number;
    buyNowFlag: boolean;
    buyNow: number;
    reservePrice: number;
    actualOffer: number;
    minPrice: number;
    isOpen: boolean;
    type: number;
    isWinner: boolean;
    isFavourite: boolean;
    confirmDate: number;
    lastOffer: {
        offerDate: Date;
        total: number;
        maxValue: number;
        vehicles: { id_vehicle: number, value: string } []
    }
    vehicles: VehicleDto[];
    docs: Doc[];

    get status(): 'INC' | 'PRO' | 'CON' | 'AGG' | 'ATT' | 'FAT' | 'NON' | 'PAG' | '_FAT' { 
        switch (this.idstatoasta) {
            case STATUS_PRONTA:
                return 'PRO';
            case STATUS_IN_CORSO:
                return 'INC';
            case STATUS_CONCLUSA:
                return 'CON';
            case STATUS_AGGIUDICATA:
                return 'AGG';
            case STATUS_POST_VENDITA:
                return 'ATT';
            case STATUS_PAGATA:
                return 'PAG';
            case STATUS_NON_AGGIUDICATA:
                return 'NON';
            case STATUS_COMPLETATA:
                return 'FAT';
            case STATUS_FATTURATA:
                return '_FAT';
            default:
                return 'PRO';
        }
    }

    get reservePriceExceeded(): boolean {
        return this.actualOffer && this.actualOffer >= this.reservePrice;
    }

    get isFinished(): boolean {
        //'CON' || 'ATT' || 'FAT' || 'PAG' || 'NON'
        return FINISHED_STATUS.indexOf(this.idstatoasta) > -1;
    }

    get isPending(): boolean {
        return this.idstatoasta === STATUS_IN_CORSO;
    }

    get mustStart(): boolean {
        return this.idstatoasta === STATUS_PRONTA;
    }

    get isPayed(): boolean {
        return this.idstatoasta === 7;
    }

    get mustApprove(): boolean {
        //'CON' || 'AGG'
        return [4, 5].indexOf(this.idstatoasta) > -1;
    }

    get getStatus(): string {
        if(this.mustApprove || this.isWinner) {
            return this.status;
        } else {
            return this.idstatoasta === STATUS_NON_AGGIUDICATA ? "notAwarded": "notWon";
        }
    }

    canDistributeValues(time: number): boolean {
        return this.vehicles?.length > 1 && this.idstatoasta === 6 && (this.confirmDate * 1000) > time;
    }

    get mustPay(): boolean {
        return this.isWinner && this.status === 'ATT';
    }

    get getActualOffer(): number | null {
        const values = [];
        if(this.lastOffer.maxValue) {
            values.push(this.lastOffer.maxValue);
        }
        if(this.actualOffer) {
            values.push(this.actualOffer);
        }

        if(values.length === 0) return this.minPrice;
        else {
            return Math.max(...values);
        }
    }

    get vehiclesCount(): {brand: string, count: number}[] {
        const vehiclesByBrand = groupByKey(this.vehicles, 'descMarca');
        const brandCount: {brand: string, count: number}[] = [];
        Object.keys(vehiclesByBrand).forEach(brand => brandCount.push({
            brand,
            count: vehiclesByBrand[brand]?.length
        }))
        return brandCount;
    }

    get icon(): string {
        if(this.mustApprove) {
            return 'pending.svg';
        } else if(this.isWinner) {
            return this.idstatoasta === STATUS_NON_AGGIUDICATA ? 'thumb-down.svg': 'thumb-up.svg';
        } else {
            return 'thumb-down.svg';
        }
    }

    get invoiceDownloadable(): boolean {
        return this.isWinner && this.idstatoasta === 10;
    }
}

export interface DistributionVehiclesDto {
    id_vehicle: number;
    value: number;
}

export interface Doc {
    id: number;
    filename: string;
    type: string;
}

export interface AuctionPricePolling {
    actualOffer: number;
    id: number;
    idstatoasta: number;
    isFavourite: boolean;
    isWinner: boolean;
    lastOffer: {
        maxValue: number;
    }
}

export enum AuctionStatusDesc {
    pending = 'pending',
    FAVOURITE = 'favourite',
    FINISHED = 'finished'
}

import { createReducer, on } from '@ngrx/store';
import { VehicleModelDto } from 'src/app/core/models/veicle-model-dto';
import * as VehicleModels from './vehicle-models.actions';

export interface State {
  filter:string;
  vehicleModels: VehicleModelDto[];
}

export const initialState: State = {
  filter: null,
  vehicleModels: []
};


export const vehicleModelsReducer = createReducer(
  initialState,
  //on(VehicleModels.LoadVehicleModels, state => state),
  on(VehicleModels.LoadVehicleModelsSuccess, (state, action) => ({
      ...state,
      ...{
        vehicleModels: action.records
      }
    })
  ),
  on(VehicleModels.LoadVehicleModelsFailure, (state, action) => initialState),
);


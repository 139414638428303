import { createAction, props } from "@ngrx/store";
import { VehicleDto } from "src/app/core/models/vehicle-dto";

export const LoadGroundingReturnedById = createAction(
  "[Grounding returned detail] Load By Id",
  props<{ id: number }>()
);
export const LoadGroundingReturnedByIdSuccess = createAction(
  "[Grounding returned detail] Load By Id Success",
  props<{ record: VehicleDto }>()
);
export const LoadGroundingReturnedByIdFailure = createAction(
  "[Grounding returned detail] Load By Id Failure"
);
export const ToggleGroundingReturnedPreference = createAction(
  "[Grounding returned selected] Toggle Preferences",
  props<{ id: number }>()
);
export const ToggleGroundingReturnedPreferenceSuccess = createAction(
  "[Grounding returned selected] Toggle Preferences Success"
);
export const ToggleGroundingReturnedPreferenceFailure = createAction(
  "[Grounding returned selected] Toggle Preferences Failure"
);
export const AcceptProposalGroundingReturned = createAction(
  "[Grounding returned selected] Accept Proposal",
  props<{ id: number }>()
);
export const AcceptProposalGroundingReturnedSuccess = createAction(
  "[Grounding returned selected] Accept Proposal Success"
);
export const AcceptProposalGroundingReturnedFailure = createAction(
  "[Grounding returned selected] Accept Proposal Failure"
);
export const RejectProposalGroundingReturned = createAction(
  "[Grounding returned selected] Reject Proposal",
  props<{ id: number }>()
);
export const RejectProposalGroundingReturnedSuccess = createAction(
  "[Grounding returned selected] Reject Proposal Success"
);
export const RejectProposalGroundingReturnedFailure = createAction(
  "[Grounding returned selected] Reject Proposal Failure"
);

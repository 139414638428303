import { createAction, props } from "@ngrx/store";
import { GroundingDto } from "src/app/core/models/grounding-dto";

export const LoadGroundingById = createAction(
  "[Grounding Detail] Load By Id",
  props<{ id: number }>()
);
export const LoadGroundingByIdSuccess = createAction(
  "[Grounding Detail] Load By Id Success",
  props<{ record: GroundingDto }>()
);
export const LoadGroundingByIdFailure = createAction(
  "[Grounding Detail] Load By Id Failure"
);

export const ToggleGroundingPreference = createAction(
  "[Grounding selected] Toggle Preferences",
  props<{ id: number }>()
);
export const ToggleGroundingPreferenceSuccess = createAction(
  "[Grounding selected] Toggle Preferences Success"
);
export const ToggleGroundingPreferenceFailure = createAction(
  "[Grounding selected] Toggle Preferences Failure"
);

import { createAction, props } from '@ngrx/store';
import { VehicleModelDto } from 'src/app/core/models/veicle-model-dto';

export const LoadVehicleModels = createAction(
  '[VehicleModels] Load VehicleModels',
  props<{filter:string}>()
);
export const LoadVehicleModelsSuccess = createAction(
  '[VehicleModels] Load VehicleModels Success',
  props<{records: VehicleModelDto[]}>()
);
export const LoadVehicleModelsFailure = createAction(
  '[VehicleModels] Load VehicleModels Failure'
);

import { Routes } from "@angular/router";
import { PasswordExpiredGuard } from "../core/guards/password-expired.guard";
import { TabsLayoutComponent } from "../layouts/tabs-layout/tabs-layout.component";
import { TimerComponent } from "../layouts/timer/timer.component";
import { UserContainerComponent } from "../modules/user/user-container/user-container.component";
import { MainAccountGuard } from "../core/guards/main-account.guard";

//Route for content layout with sidebar, navbar and footer.

export const FULL_ROUTES: Routes = [
  {
    path: "auction",
    component: TimerComponent,
    canActivate: [PasswordExpiredGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "../pages/full-pages/auction-page/auction-page.component"
          ).then((m) => m.AuctionPageModule),
      },
      {
        path: "detail/:id",
        loadChildren: () =>
          import(
            "../pages/full-pages/auction-detail/auction-detail.module"
          ).then((m) => m.AuctionDetailModule),
      },
    ],
  },
  {
    path: "grounding",
    canActivate: [PasswordExpiredGuard],
    children: [
      {
        path: "",
        loadChildren: () =>
          import(
            "../pages/full-pages/grounding-page/grounding-page.component"
          ).then((m) => m.GroundingPageModule),
      },
      {
        path: "detail/:id",
        loadChildren: () =>
          import(
            "../pages/full-pages/grounding-detail/grounding-detail.module"
          ).then((m) => m.GroundingDetailModule),
      },
      {
        path: ":status/detail/:id",
        loadChildren: () =>
          import(
            "../pages/full-pages/grounding-detail/grounding-detail.module"
          ).then((m) => m.GroundingDetailModule),
      },
    ],
  },
  {
    path: "news",
    component: TabsLayoutComponent,
    canActivate: [PasswordExpiredGuard],
    children: [
      {
        path: "last",
        loadChildren: () =>
          import(
            "../pages/full-pages/news-list-page/news-list-page.module"
          ).then((m) => m.NewsListPageModule),
      },
    ],
  },
  {
    path: "favorites",
    canActivate: [PasswordExpiredGuard],
    loadChildren: () =>
      import("../pages/full-pages/favorites-page/favorites-page.module").then(
        (m) => m.FavoritesPageModule
      ),
  },
  {
    path: "user",
    component: UserContainerComponent,
    children: [
      {
        path: "company",
        canActivate: [PasswordExpiredGuard],
        loadChildren: () =>
          import(
            "../pages/full-pages/user-company-page/user-company-page.module"
          ).then((m) => m.UserCompanyPageModule),
      },
      {
        path: "profile",
        canActivate: [PasswordExpiredGuard],
        loadChildren: () =>
          import(
            "../pages/full-pages/user-profile-page/user-profile-page.module"
          ).then((m) => m.UserProfilePageModule),
      },
      {
        path: "password",
        loadChildren: () =>
          import(
            "../pages/full-pages/user-password-page/user-password-page.module"
          ).then((m) => m.UserPasswordPageModule),
      },
      {
        path: "add-account",
        canActivate: [PasswordExpiredGuard],
        data: {
          rmk_createaccount: true,
        },
        loadChildren: () =>
          import(
            "../pages/full-pages/add-user-account-page/add-user-account-page.module"
          ).then((m) => m.AddUserAccountPageModule),
      },
      {
        path: "agency-form-pdf",
        canActivate: [PasswordExpiredGuard],
        loadChildren: () =>
          import(
            "../pages/full-pages/agency-form-page/agency-form-page.module"
          ).then((m) => m.AgencyFormPageModule),
      },
      {
        path: "terms",
        canActivate: [PasswordExpiredGuard],
        loadChildren: () =>
          import(
            "../pages/full-pages/user-terms-page/user-terms-page.module"
          ).then((m) => m.UserTermsPageModule),
      },
      {
        path: "privacy",
        canActivate: [MainAccountGuard, PasswordExpiredGuard],
        data: {
          main_account: true,
        },
        loadChildren: () =>
          import(
            "../pages/full-pages/user-privacy-page/user-privacy-page.module"
          ).then((m) => m.UserPrivacyPageModule),
      },
      {
        path: "support",
        canActivate: [PasswordExpiredGuard],
        loadChildren: () =>
          import(
            "../pages/full-pages/user-support/user-support-page.module"
            ).then((m) => m.UserSupportPageModule),
      },
    ],
  } /*
  { 
    path: 'services',
    component: TabsLayoutComponent,
    children: [
      { 
        path: 'ship-document',
        loadChildren: () => import('../pages/full-pages/ship-document-page/ship-document-page.module').then(m => m.ShipDocumentPageModule)
      }
    ]
  },*/,
];

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Notification } from '../../models/notification-dto';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient
  ){
  }

  Gets(): Observable<Notification[]>{
    return this.http.get<Notification[]>(environment.apiUrl + 'buyer/profile/notifications');
  }

  MarksAsRead(){
    return this.http.post(environment.apiUrl + 'buyer/profile/readNotification', {});
  }
}

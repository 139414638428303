import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import {MakeDto} from "../../models/make-dto";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MakeService {

  constructor(private http: HttpClient){}

  Gets(): Observable<MakeDto[]>{
    return this.http.get<{
      success: boolean,
      data?: {[key: string]: string}
    }>(environment.apiUrl + 'admin/make').pipe(
      map(response => {
        if (!response.success) {
          return [];
        }
        return Object.keys(response.data).map(key => ({value: key, label: response.data[key]}));
      })
    );
  }
}

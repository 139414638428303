import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { ErrorComponent } from "./components/error/error.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { NotificationMenuComponent } from "../modules/notification/notification-menu/notification-menu.component";
import { UserFormLoadingComponent } from "./components/user-form-loading/user-form-loading.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { CanOfferDirective } from "../core/directives/can-offer.directive";
import { NotificationBellComponent } from "./components/notification-bell/notification-bell.component";
import { NgPipesModule } from "ngx-pipes";
import { ChangeLanguageComponent } from './navbar/change-language/change-language.component';

@NgModule({
  exports: [
    FooterComponent,
    NavbarComponent,
    ErrorComponent,
    NotificationComponent,
    LazyLoadImageModule,
    NgPipesModule,
    UserFormLoadingComponent,
    CanOfferDirective,
    NotificationBellComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgPipesModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    ErrorComponent,
    NotificationComponent,
    NotificationMenuComponent,
    UserFormLoadingComponent,
    CanOfferDirective,
    NotificationBellComponent,
    ChangeLanguageComponent
  ],
  entryComponents: [],
  providers: [],
})
export class SharedModule {}

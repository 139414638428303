import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-form-loading',
  templateUrl: './user-form-loading.component.html'
})
export class UserFormLoadingComponent implements OnInit {
  @Input() fieldsCount: number = 10;
  public fields: number[] = [];

  ngOnInit(): void {
    this.fields = Array(this.fieldsCount);
  }
}

import { createReducer, on } from '@ngrx/store';
import { Notification } from 'src/app/core/models/notification-dto';
import * as NotificationActions from './notification.actions';

export const notificationFeatureKey = 'notification';
export const DEFAULT_LANGUAGE_CODE = 'it';

export interface State {
  isFetching: boolean;
  error: any;
  notifications: Notification[];
  mustRead: number;
  menuOpened: boolean;
}

export const initialState: State = {
  isFetching: false,
  error: null,
  notifications: [],
  mustRead: 0,
  menuOpened: false
};


export const notificationReducer = createReducer(
  initialState,

  on(NotificationActions.GetNotifications, (state, action) => ({
    ...state,
    ...{
      isFetching: false,
      error: null
    }
  })),

  on(NotificationActions.GetNotificationSuccess, (state, action) => ({
    ...state,
    ...{
      isFetching: false,
      error: null,
      notifications: action.notifications,
      mustRead: action.notifications?.length
    }
  })),

  on(NotificationActions.ToggleNotificationsMenu, (state) => ({
    ...state,
    ...{
      menuOpened: !state.menuOpened,
      mustRead: !state.menuOpened ? 0 : state.mustRead
    }
  }))

);


import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, map, switchMap } from 'rxjs/operators';
import * as NewsActions from './news.actions';
import { Store } from '@ngrx/store';
import { NewsService } from 'src/app/core/services/api-wrapped/news.service';
import { State } from './news.reducer';
import { markNewsAsRead } from '../auth/auth.actions';

@Injectable()
export class NewsEffects {
  constructor (
    private actions$: Actions,
    private store: Store<State>,
    private newsService: NewsService
  ) {}

  loadNewss$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsActions.LoadNews),
      switchMap(action => this.newsService.Gets()),
      switchMap(records => {
        return records.length > 0 ? [NewsActions.LoadNewsSuccess({records}), markNewsAsRead() ] : [NewsActions.LoadNewsFailure()];
      })
    )
  });

  DownloadNewsAttachment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(NewsActions.DownloadNewsAttachment),
      concatMap(action => this.newsService.DownloadFile(action.newsId, action.fileId)),
      map(response => {
        const blob = new Blob([response?.body], { type: response?.body?.type });
        const url= window.URL.createObjectURL(blob);
        window.open(url);
      })
    )
  }, { dispatch: false });

}

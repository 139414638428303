import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {Store} from "@ngrx/store";
import {selectSecurity} from "../../features/auth/auth.selectors";

@Injectable({
  providedIn: 'root'
})
export class MainAccountGuard implements CanActivate {

  public mainAccount: boolean;

  constructor(
      private store: Store<{}>,
      private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.select(selectSecurity).subscribe(securityState => {
      this.mainAccount = securityState.main_account;
    });
    if(!this.mainAccount) {
      this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
    }
    return this.mainAccount;
  }
}

import {MakeDto} from "../core/models/make-dto";

export const PREEMPTION_CONFIRMED = {
  label: "status.preemption_accepted",
  value: 2,
};

export const QUOTATION_CONFIRMED = {
  label: "status.accepted",
  value: 2,
};

export const QUOTATION_WAITING = {
  label: "status.quotation_waiting",
  value: 3,
};

export const QUOTATION_SENT = {
  label: "status.quotation_sent",
  value: 4,
};

export const QUOTATION_ACCEPTED = {
  label: "status.quotation_accepted",
  value: 5,
};

export const QUOTATION_REJECTED = {
  label: "status.quotation_rejected",
  value: 6,
};

export const QUOTATION_NOT_ACHIEVABLE = {
  label: "status.quotation_not_achievable",
  value: 7,
};

export const QUOTATION_STATES = [
  QUOTATION_CONFIRMED,
  QUOTATION_WAITING,
  QUOTATION_SENT,
  QUOTATION_ACCEPTED,
  QUOTATION_REJECTED,
  QUOTATION_NOT_ACHIEVABLE
];

export const MAKES: MakeDto[] = [
  { value: "VW", label: "VW" },
  { value: "SK", label: "SKODA" },
  { value: "AU", label: "AUDI" },
  { value: "SE", label: "SEAT" },
  { value: "PO", label: "PORSCHE" },
  { value: "MF", label: "MAN" },
  { value: "CU", label: "CUPRA" }
];

export const PAGE_SIZE = 25;
export const HEADER_TOTAL_COUNT = 'x-total-count';

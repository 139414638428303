import { createReducer, on } from '@ngrx/store';
import { News } from 'src/app/core/models/news-dto';
import * as NewsActions from './news.actions';

export interface State {
  news: News[];
  isFetching: boolean;
}

export const initialState: State = {
  news: [],
  isFetching: false
};


export const newsReducer = createReducer(
  initialState,

  on(NewsActions.LoadNews, state => ({
    ...state,
    ...{
      isFetching: true
    }
  })),
  on(NewsActions.LoadNewsSuccess, (state, action) => ({
      ...state,
      ...{
        news: action.records,
        isFetching: false
      }
    })
  ),
  on(NewsActions.LoadNewsFailure,  state => ({
    ...state,
    ...{
      isFetching: false
    }
  })),
);


<div *ngIf="message" class="sg-component-variant">
    <aside class="c-toast-notifications js-toast-notifications" [attr.aria-label]="message">
        <article [ngClass]="{'c-toast-notification--success': level === 'success', 'c-toast-notification--error': level === 'error'}" class="c-toast-notification " aria-live="polite" role="status">
            <div class="c-toast-notification__content">
                <div class="c-toast-notification__body">
                    <p [innerHtml]="message"></p>
                </div>
                <button class="c-toast-notification__close" (click)="hideToast()" type="button" aria-expanded="true"></button>
            </div>
        </article>  
    </aside>
</div>
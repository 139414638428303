import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ForgotPasswordDto, ForgotPasswordResponseDto, ForgotPasswordVerifyPinDto, ForgotPasswordVerifyPinResponseDto, LoginDto, LoginResponseDto, SignupDto, SignupResponseDto } from "../../models/auth-dto";
import { Privacy, AddUserAccountDto, AgencyFormDto, BuyerData, ChangePasswordDto, SaveProfileDto, UserFormResponseDto } from "../../models/user-dto";

@Injectable({
    providedIn: 'root',
  })
  export class UserService {
    constructor(
      private http: HttpClient
    ) {
    }

    login(body?: LoginDto): Observable<LoginResponseDto> {
      return this.http.post<LoginResponseDto>(environment.apiUrl  + 'oauth/access_token', {
        username: body.login,
        password: body.password
      });
    }

    signup(body: SignupDto): Observable<SignupResponseDto> {
      return this.http.post<SignupResponseDto>(environment.apiUrl  + 'buyer/registration', body);
    }

    forgotPassword(body: ForgotPasswordDto): Observable<ForgotPasswordResponseDto> {
      return this.http.post<ForgotPasswordResponseDto>(environment.apiUrl  + 'password', body);
    }

    forgotPasswordVerifyPin(body: ForgotPasswordVerifyPinDto): Observable<ForgotPasswordVerifyPinResponseDto> {
      return this.http.post<ForgotPasswordVerifyPinResponseDto>(environment.apiUrl  + 'pin', body);
    }

    acceptTerms(hideToc: boolean): Observable<UserFormResponseDto> {
      return this.http.post<UserFormResponseDto>(environment.apiUrl + 'buyer/preferences', {
        hide_toc: hideToc
      });
    }

    saveUserCompany(payload: BuyerData): Observable<UserFormResponseDto> {
      return this.http.post<UserFormResponseDto>(environment.apiUrl + 'buyer/profile', payload);
    }

    saveUserPassword(payload: ChangePasswordDto): Observable<UserFormResponseDto> {
      return this.http.post<UserFormResponseDto>(environment.apiUrl + 'buyer/password', payload);
    }
    
    saveUserProfile(payload: SaveProfileDto): Observable<UserFormResponseDto> {
      return this.http.post<UserFormResponseDto>(environment.apiUrl + 'buyer/mobile', {
        mobile: payload.mobile
      });
    }

    addUserAccount(payload: AddUserAccountDto): Observable<UserFormResponseDto> {
      return this.http.post<UserFormResponseDto>(environment.apiUrl + 'buyer/add_user_account', payload);
    }

    downloadAgencyFormPdf(data: AgencyFormDto) {   
      return this.http.post(
          environment.apiUrl + 'buyer/agency_module', 
          data,
          {observe: 'response', responseType: 'blob'}
      );
  }

  privacySettings(data: Privacy) {
    return this.http.post(
        environment.apiUrl + 'buyer/profile/privacy',
        data
    );
  }
}

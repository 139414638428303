import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {Observable} from "rxjs";
import { environment } from "src/environments/environment";
import { GroundingDto } from "../../models/grounding-dto";
import {GroundingFilterDto, GroundingListRequestDto} from "../../models/grounding-filter-dto";
import { VehicleDto } from "../../models/vehicle-dto";
import {
  PREEMPTION_CONFIRMED,
  QUOTATION_NOT_ACHIEVABLE,
  QUOTATION_REJECTED,
  QUOTATION_SENT,
  QUOTATION_WAITING
} from "../../../shared/constants";

@Injectable({
  providedIn: "root",
})
export class GroundingService {
  constructor(private _httpClient: HttpClient) {}

  TogglePreference(id: number): Observable<GroundingDto[]> {
    let url = `${environment.apiUrl}buyer/fleetvehicles/${id}/firstoption`;

    let params = new HttpParams();

    return this._httpClient.post<GroundingDto[]>(url, { params });
  }

  ToggleReturnedPreference(id: number): Observable<GroundingDto[]> {
    let url = `${environment.apiUrl}buyer/vehicles/grounding/${id}/firstoption`;

    let params = new HttpParams();

    return this._httpClient.post<GroundingDto[]>(url, { params });
  }

  GetById(id: number): Observable<GroundingDto> {
    let url = `${environment.apiUrl}buyer/fleetvehicles/${id}`;

    let params = new HttpParams();

    return this._httpClient.get<GroundingDto>(url, { params });
  }

  GetReturnedById(id: number): Observable<VehicleDto> {
    let url = `${environment.apiUrl}buyer/vehicles/grounding/${id}`;

    let params = new HttpParams();

    return this._httpClient.get<VehicleDto>(url, { params });
  }

  Gets(requestParams: GroundingListRequestDto): Observable<GroundingDto[]> {
    let url: string = `${environment.apiUrl}buyer/fleetvehicles`;
    let params = new HttpParams();

    if (requestParams.filters.plate) {
      url += "/plate/" + requestParams.filters.plate;
    }

    params = params.set("page", requestParams.page.toString());

    return this._httpClient.get<GroundingDto[]>(url, { params });
  }

  GetsReturned(requestParams: GroundingListRequestDto): Observable<VehicleDto[]> {
    let url = `${environment.apiUrl}buyer/vehicles/grounding`;

    let params = new HttpParams();
    params = params.set('page', requestParams.page.toString());
    params = this.buildHttpParams(params, requestParams.filters);

    return this._httpClient.get<VehicleDto[]>(url, { params });
  }

  AcceptProposal(id: number): Observable<{auction_id: number}> {
    let url = `${environment.apiUrl}buyer/firstoptions/${id}/accept`;

    return this._httpClient.post<{auction_id: number}>(url, {});
  }

  RejectProposal(id: number): Observable<void> {
    let url = `${environment.apiUrl}buyer/firstoptions/${id}/reject`;

    return this._httpClient.post<void>(url, {});
  }

  private buildHttpParams(httpParams: HttpParams, filters: GroundingFilterDto) {

    if (filters.plate) {
      httpParams = httpParams.set('plate', filters.plate);
    }

    if (filters.quotationWaiting) {
      httpParams = httpParams.append('statuses[]', QUOTATION_WAITING.value);
    }

    if (filters.quotationSent) {
      httpParams = httpParams.append('statuses[]', QUOTATION_SENT.value);
    }

    if (filters.preemptionConfirmed) {
      httpParams = httpParams.append('statuses[]', PREEMPTION_CONFIRMED.value);
    }

    if (filters.preemptionAchievable) {
      httpParams = httpParams.set('preemption_achievable', 1);
    }

    if (filters.quotationRejected) {
      httpParams = httpParams.append('statuses[]', QUOTATION_REJECTED.value);
    }

    if (filters.quotationNotAchievable) {
      httpParams = httpParams.append('statuses[]', QUOTATION_NOT_ACHIEVABLE.value);
    }

    return httpParams;
  }
}

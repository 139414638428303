<footer class="c-footer">

    <div class="c-footer__module  c-footer__module--nav u-hide@s">
        <div class="o-page-wrap">
            <nav class="c-footer-nav" aria-label="footer-nav">
                <div class="c-footer-nav__columns">
                    <div class="c-footer-nav__column  js-footer-accordion">
                        <div class="c-footer-nav__title  js-footer-accordion__title">
                            <h4 class="c-footer-nav__title-label  js-footer-accordion__title-label">
                                {{'footer.support' | translate}}
                            </h4>
                        </div>
                        <ul class="c-footer-nav__list  js-footer-accordion__panel">
                            <li class="c-footer-nav__item">
                                <button class="c-btn  c-btn--link  c-btn--round  c-btn--small" data-tr-component="Button Link" >
                                    <i class="c-btn__icon c-icon  c-icon--[semantic-phone]" aria-hidden="true" role="img"></i>
                                    <span class="c-btn__text u-text-pale">
                                        Interlogica s.r.l. (041) 5354800
                                    </span>
                                </button>
                            </li>
                            <li class="c-footer-nav__item">
                                <a href="mailto:assistenza@interlogica.it" class="c-btn  c-btn--link  c-btn--round  c-btn--small" data-tr-component="Button Link" >
                                    <i class="c-btn__icon c-icon  c-icon--[semantic-mail]" aria-hidden="true" role="img"></i>
                                    <span class="c-btn__text u-text-pale">
                                        assistenza@interlogica.it
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="c-footer-nav__column  js-footer-accordion">
                        <div class="c-footer-nav__title  js-footer-accordion__title">
                            <h4 class="c-footer-nav__title-label  js-footer-accordion__title-label">
                                {{'footer.docsSupport' | translate}}
                            </h4>
                        </div>
                        <ul class="c-footer-nav__list  js-footer-accordion__panel">
                            <li *ngFor="let doc of supportDocs" class="c-footer-nav__item">
                                <a [href]="apiUrl + ('footer.docs.' + doc.path | translate)" target="_blank" rel="noopener" class="c-footer-nav__link">
                                    {{'footer.docs.' + doc.label | translate}}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="c-footer-nav__column  js-footer-accordion">
                        <div class="c-footer-nav__title  js-footer-accordion__title">
                            <h4 class="c-footer-nav__title-label  js-footer-accordion__title-label">
                                {{'footer.docsInfo' | translate}}
                            </h4>
                        </div>
                        <ul class="c-footer-nav__list  js-footer-accordion__panel">
                            <li *ngFor="let doc of infoDocs" class="c-footer-nav__item">
                                <a [href]="(doc.useApiUrl ? apiUrl : '') + ('footer.docs.' + doc.path | translate)" target="_blank" rel="noopener" class="c-footer-nav__link">
                                    {{'footer.docs.' + doc.label | translate}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="c-footer__module  c-footer__module--meta-nav">
        <div class="o-page-wrap">
            <nav class="c-footer-meta-bar" aria-label="footer-nav-meta">
                <ul class="c-footer-social-media">
                    
                </ul>
                <span class="c-footer-copyright">
                    <span class="c-footer-copyright__sign">©</span>
                    <span class="c-footer-copyright__text">{{'footer.copyright' | translate}}</span>
                </span>
            </nav>
        </div>
    </div>

</footer>

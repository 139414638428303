import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, concatMap, catchError, exhaustMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import {
  GetAuctionPriceList,
  GetAuctionPriceListFailure,
  GetAuctionPriceListSuccess,
  GetCounterAuctionsToPay,
  GetCounterAuctionsToPayFailure,
  LoadAuctions,
  LoadAuctionsFailure,
  LoadAuctionsSuccess,
  ApplyAuctionsFilters,
  SetAuctionsNextPage,
  SetAuctionsSort,
  SetCounterToPay,
  ResetAuctionsFilters,
  DownloadExcel,
  LoadMakesFilter,
  LoadMakesFilterFailure,
  LoadMakesFilterSuccess
} from './auction.actions';
import { Store } from '@ngrx/store';
import { State } from './auction.reducer';
import { selectAuctionState } from './auction.selectors';
import { AuctionService } from 'src/app/core/services/api-wrapped/auction.service';
import { StaticValuesService } from 'src/app/core/services/api-wrapped/static-values.service';
import { GetStaticValuesSuccess } from '../layout/layout.actions';
import { selectLayoutState } from '../layout/layout.selectors';
import { TranslateService } from '@ngx-translate/core';
import {MakeService} from "../../core/services/api-wrapped/make.service";

@Injectable()
export class AuctionEffects {
  constructor (
    private actions$: Actions,
    private store: Store<State>,
    private auctionService: AuctionService,
    private staticValuesService: StaticValuesService,
    private translateService: TranslateService,
    private makeService: MakeService
  ) {}

  loadAuctions$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(LoadAuctions),
        concatLatestFrom(action => this.store.select(selectAuctionState)),
        concatLatestFrom(([action, store]) => this.store.select(selectLayoutState).pipe(
          map(res => ({
            auctionSlice: store,
            staticValuesSlice: res.staticValues
          }))
        )),
        switchMap(([action, store]) =>
          forkJoin([
            store.staticValuesSlice.fastBidStep ? of(store.staticValuesSlice) : this.staticValuesService.Get(),
            store.auctionSlice.statusDesc !== 'finished' && store.auctionSlice.auctions.length > 0 ?
              of({ data: store.auctionSlice.auctions, totalCount: store.auctionSlice.auctions.length }) :
              this.auctionService.Gets(store.auctionSlice.statusDesc === 'finished', {
                filters: store.auctionSlice.filters,
                page: store.auctionSlice.page,
                sortBy: store.auctionSlice.sortBy,
                sortOrder: store.auctionSlice.sortOrder
              }),
            of(store.auctionSlice)
          ]).pipe(
            switchMap(res => {
              const actionsSuccess = [];
              const totalCount = res[1].totalCount;
              const records = res[1].data;
              const state = res[2];
              actionsSuccess.push(
                GetStaticValuesSuccess({data: res[0]}),
                LoadAuctionsSuccess({
                  records,
                  canLoadMoreRecords: store.auctionSlice.statusDesc === 'finished' ?
                    state.auctionsFinished.length + records.length < totalCount : false
                }),
                GetCounterAuctionsToPay()
              );
              return res.length > 0 ? actionsSuccess : of(LoadAuctionsFailure());
            }),
            catchError(err => of(LoadAuctionsFailure()))
          )
        )
      )
    }
  );

  setAuctionsNextPage$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SetAuctionsNextPage),
        concatLatestFrom(action => this.store.select(selectAuctionState)),
        map(([action, store]) => LoadAuctions({status: store.statusDesc}))
      )
    }
  );

  applyAuctionsFilters$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(ApplyAuctionsFilters),
        concatLatestFrom(action => this.store.select(selectAuctionState)),
        map(([action, store]) => LoadAuctions({status: store.statusDesc}))
      )
  });

  resetAuctionsFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResetAuctionsFilters),
      concatLatestFrom(action => this.store.select(selectAuctionState)),
      map(([action, store]) => LoadAuctions({status: store.statusDesc}))
    )
  });

  loadMakesFilter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LoadMakesFilter),
      switchMap(store => {
        const obs = this.makeService.Gets();
        return obs.pipe(
          map(makes => LoadMakesFilterSuccess({makes})),
          catchError(err => [LoadMakesFilterFailure()])
        )
      }),
    )
  });

  setAuctionsSort$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(SetAuctionsSort),
        concatLatestFrom(action => this.store.select(selectAuctionState)),
        map(([action, store]) => LoadAuctions({status: store.statusDesc}))
      )
    }
  );

  getCounterAuctionsToPay$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetCounterAuctionsToPay),
      concatLatestFrom(action => this.store.select(selectAuctionState)),
      switchMap(([action, store]) => {
        const obs = store.toPay ? of(store.toPay): this.auctionService.GetCounterAuctionsToPay();
        return obs.pipe(
          map(count => SetCounterToPay({count})),
          catchError(err => [
            GetCounterAuctionsToPayFailure()
          ])
        )
      })
    )
  });

  getAuctionPriceList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetAuctionPriceList),
      concatLatestFrom(action => this.store.select(selectAuctionState)),
      exhaustMap(([action, store]) => {
        const ids = store.auctions.map(auction => auction.id);
        const obs = store.statusDesc === 'finished' || ids.length === 0 ? of([]): this.auctionService.GetAuctionPriceList(ids);
        return obs.pipe(
          map(res => GetAuctionPriceListSuccess({priceList: res})),
          catchError(err => [
            GetAuctionPriceListFailure()
          ])
        )
      })
    )
  });

  DownloadExcel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DownloadExcel),
      concatLatestFrom(action => this.store.select(selectAuctionState)),
      concatMap(([action, store]) =>
      this.auctionService.DownloadFile({
          filters: store.filters,
          page: store.page,
          sortBy: store.sortBy,
          sortOrder: store.sortOrder
        })
      ),
      map(response => {
        const blob = new Blob([response?.body], { type: response?.body?.type });
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = this.translateService.instant('auctionReportXls') + ".xls";
        a.click();
        URL.revokeObjectURL(objectUrl);
      })
    )
  }, { dispatch: false });
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, timer } from 'rxjs';
import { HideNotifyMessage } from 'src/app/features/layout/layout.actions';

@Component({
  selector: 'app-toast-notify',
  templateUrl: './toast-notify.component.html'
})
export class ToastNotifyComponent implements OnInit, OnDestroy {
  @Input() message: string;
  @Input() level: string;

  private subscriptions: Subscription[] = [];
  
  constructor(
    private store: Store<{}>
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      timer(4000).subscribe(_ => this.hideToast())
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public hideToast(): void {
    this.store.dispatch(HideNotifyMessage())
  }

}

import { createReducer, on } from '@ngrx/store';
import { VehicleModelDto } from 'src/app/core/models/veicle-model-dto';
import * as Favorites from './favorites.actions';

export interface State {
  filter:string;
  favorites: VehicleModelDto[];
  isFetching: boolean;
}

export const initialState: State = {
  filter: null,
  favorites: [],
  isFetching: false
};


export const favoritesReducer = createReducer(
  initialState,

  // Loading
  on(Favorites.LoadFavorites, Favorites.AddFavorite, state => ({
    ...state,
    ...{
      isFetching: true
    }
  })),
  on(Favorites.LoadFavoritesSuccess, (state, action) => ({
      ...state,
      ...{
        favorites: action.records,
        isFetching: false
      }
    })
  ),
  on(Favorites.LoadFavoritesFailure, (state, action) => initialState),
  on(Favorites.AddFavoriteSuccess, (state, action) => ({
    ...state,
    ...{
     // favorites: [...state.favorites, action.record],
      isFetching: false
    }
  })
  ),
  on(Favorites.AddFavoriteFailure, (state, action) => ({
    ...state,
    ...{
      isFetching: false
    }
  })),
  on(Favorites.RemoveFavoriteSuccess, (state, action) => ({
    ...state,
    ...{
      favorites: state.favorites.filter(x=> x.id != action.id)
    }
  })),
);


import { createReducer, on } from '@ngrx/store';
import { StaticValues } from 'src/app/core/models/static-values-dto';
import { CloseBanner, CloseFiltersMenu, GetServerTimestampSuccess, GetStaticValuesSuccess, HideNotifyMessage, ShowNotifyMessage, ToggleFiltersMenu } from './layout.actions';
import { getCookie } from 'src/app/core/functions/cookies.functions';

export interface State {
  filtersMenuVisible: 'opened' | 'closed' | null,
  timestamp: number,
  staticValues: StaticValues,
  toastNotifyMessage: string,
  toastNotifyLevel: 'success' | 'error' | null,
  disclaimerVisited: {
    auctionList: boolean;
    auctionDetail: boolean;
  };
}

export const initialState: State = {
  filtersMenuVisible: null,
  timestamp: new Date().getTime() / 1000,
  staticValues: {
    provinces: [],
    countries: [],
    fastBidStep: null
  },
  toastNotifyMessage: null,
  toastNotifyLevel: null,
  disclaimerVisited: {
    auctionList: !!getCookie('auctionList'),
    auctionDetail: !!getCookie('auctionDetail')
  },
};

export const layoutReducer = createReducer(
  initialState,
  on(ToggleFiltersMenu, (state, action) => ({
      ...state,
      ...{
        filtersMenuVisible: state.filtersMenuVisible === 'closed' || !state.filtersMenuVisible ? 'opened' : 'closed'
      }
    })
  ),

  on(CloseFiltersMenu, (state, action) => ({
    ...state,
    ...{
      filtersMenuVisible: null
    }
  })),

  on(CloseBanner, (state, action) => ({
    ...state,
    ...{
      disclaimerVisited: {
        ...initialState.disclaimerVisited,
        ...{
          [action.bannerType]: true
        }
      }
    }
  })),

  on(GetServerTimestampSuccess, (state, action) => ({
    ...state,
    ...{
      timestamp: action.timestamp
    }
  })),

  on(GetStaticValuesSuccess, (state, action) => {
    return{
      ...state,
      ...{
        staticValues: {
          countries: action.data.countries,
          provinces: action.data.provinces,
          fastBidStep: action.data.fastBidStep
        }
      }
    }}
  ),

  on(ShowNotifyMessage, (state, action) => {
        let errorMessage:string;
        if (typeof (action.message) === 'string') {
          errorMessage = action.message;
        } else {
          errorMessage = action.message.errors.join(' - ');
        }

        return ({
          ...state,
          ...{
            toastNotifyMessage: errorMessage,
            toastNotifyLevel: action.level ? action.level : 'success'
          }
        });
      }
  ),

  on(HideNotifyMessage, (state, action) => ({
      ...state,
      ...{
        toastNotifyMessage: null,
        toastNotifyLevel: null
      }
    })
  ),
);

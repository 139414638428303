import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromGrounding from "./grounding-selected.reducer";

export const selectGroundingSelectedState =
  createFeatureSelector<fromGrounding.State>("selectedGrounding");

export const selectGroundingSelected = createSelector(
  selectGroundingSelectedState,
  (state) => state.details
);

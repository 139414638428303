import { createAction, props } from "@ngrx/store";
import { GroundingDto } from "src/app/core/models/grounding-dto";

export const LoadGrounding = createAction("[Grounding] Load Grounding");
export const LoadGroundingSuccess = createAction(
  "[Grounding] Load Grounding Success",
  props<{ records: GroundingDto[]; canLoadMoreRecords: boolean }>()
);
export const LoadGroundingFailure = createAction(
  "[Grounding] Load Grounding Failure"
);
export const SetGroundingNextPage = createAction("[Grounding] Next Page");
export const SetGroundingFilter = createAction(
  "[Grounding] Set Filter",
  props<{ filter: { [key: string]: any } }>()
);
export const ApplyGroundingFilters = createAction("[Grounding] Apply Filters");
export const ResetGroundingFilters = createAction("[Grounding] Reset Filters");
export const ToggleGroundingPreferenceListSuccess = createAction(
  "[Grounding] Toggle Preference List Success",
  props<{ id: number }>()
);

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Subscription } from 'rxjs';
import { ToggleNotificationsMenu } from 'src/app/features/notification/notification.actions';
import { selectNotificationState } from 'src/app/features/notification/notification.selectors';

@Component({
  selector: 'app-notification-bell',
  templateUrl: './notification-bell.component.html',
  styleUrls: ['./notification-bell.component.scss'],
})
export class NotificationBellComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  public notifications = 0;
  public notificationsMenuOpened = false;
  
  constructor(
    private store: Store<{}>,
    public gaService: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.store.select(selectNotificationState).subscribe(state => {
        this.notifications = state.mustRead;
      }),
    );
  }

  
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public toggleMenuNotifications(): void {
    this.gaService.event('menu_Notify');
    this.store.dispatch(ToggleNotificationsMenu());
  }

}
